import styled from 'styled-components';
import { ReactNode } from 'react';
import Spinner from './Spinner';

const Wrapper = styled.div``;

type iComponentWrapper = {
  componentName: string;
  children: ReactNode | ReactNode[];
  testId?: string;
  className?: string;
  isLoading?: boolean;
};
const ComponentWrapper = ({
  componentName,
  testId,
  className,
  children,
  isLoading = false,
}: iComponentWrapper) => {
  const testIdStr = `${testId || ''}-${componentName}`;
  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${className || ''} ${componentName}`}
    >
      {isLoading === true ? <Spinner /> : children}
    </Wrapper>
  );
};
export default ComponentWrapper;
