import React from 'react';
import {
  iEntityFormField,
  iEntityFormFieldErrorProps,
  iUseAsForm,
} from '../form/EntityEditPanel';
import TextField, { iTextFieldValue } from '../frameWork/TextField';
import iProduct from '../../types/product/iProduct';
import ConversionText from './ConversionText';

type iConversionProps = {
  props: Omit<iEntityFormField, 'renderComponent' | 'isValid'>;
  useAsForm: iUseAsForm;
  errorProps: iEntityFormFieldErrorProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contactCompanyProduct?: any;
  product?: iProduct;
};
export const Conversion = ({
  props,
  useAsForm,
  errorProps,
  contactCompanyProduct,
  product,
}: iConversionProps) => {
  const { fieldName, ...restProps } = props;
  return (
    <>
      <TextField
        {...restProps}
        {...errorProps}
        value={restProps.value as iTextFieldValue}
        onChange={(event) =>
          useAsForm.onFieldChange(
            fieldName,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            event.target.value || '',
          )
        }
      />
      {contactCompanyProduct?.unitConversion &&
        contactCompanyProduct?.Measurement?.name &&
        product?.UOM?.name && (
          <ConversionText
            value={1}
            conversion={Number(contactCompanyProduct.unitConversion)}
            contactCompanyUnit={contactCompanyProduct.Measurement.name}
            productUnit={product.UOM.name}
          />
        )}
    </>
  );
};
