import iAttributeSet from '../../types/attribute/iAttributeSet';

export enum AttrSetSettingFieldNames {
  displayGroup = 'displayGroup',
}

export type iAttrSetDisplayGroup = {
  id: string;
  groupName: string;
  attrIds: string[]; //Attribute IDs
};
export type iAttrSetDisplaySection = {
  sectionId: string; //Attribute ID
  groups: iAttrSetDisplayGroup[];
};
const getDisplayGroup = (
  attributeSet?: iAttributeSet,
): iAttrSetDisplaySection => {
  const settings = attributeSet?.settings || {};
  return AttrSetSettingFieldNames.displayGroup in settings
    ? settings[AttrSetSettingFieldNames.displayGroup]
    : null;
};
const formDisplayGroup = (
  attributeSet?: iAttributeSet,
  newSettings?: iAttrSetDisplaySection | null,
): {
  [AttrSetSettingFieldNames.displayGroup]: iAttrSetDisplaySection | null;
} => {
  const config = getDisplayGroup(attributeSet);
  return {
    [AttrSetSettingFieldNames.displayGroup]:
      !config && !newSettings
        ? null
        : {
            ...(config || {}),
            ...(newSettings || {}),
          },
  };
};

const AttributeSetSettingsHelper = {
  getDisplayGroup,
  formDisplayGroup,
};

export default AttributeSetSettingsHelper;
