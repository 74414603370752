import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import Toaster from '../common/Toaster';
import BuildService from '../../services/build/BuildService';
import iBuild from '../../types/build/iBuild';
import AddressEditPanel from '../address/AddressEditPanel';
import ContactCompanySelector from '../contact/ContactCompanySelector';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';
import { iOption, iOptionWithData } from '../frameWork/Select';
import { getErrorProps, iErrorMap } from '../form/FormError';
import AddressService from '../../services/system/AddressService';
import BuildStyleSelector from '../buildStyle/BuildStyleSelector';
import EstateSelector from '../estate/EstateSelector';

export type iBuildCreatePopupBtn = Omit<iPopupBtn, 'titleId' | 'modalProps'> & {
  onCreated: (created: iBuild) => void;
};
const BuildCreatePopupBtn = ({ onCreated, ...props }: iBuildCreatePopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setShowingModal: iSetShowingModalFn) => {
    setEditingData({});
    setErrorMap({});
    setShowingModal(false);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const clientId = `${editingData.clientId || ''}`.trim();
    const styleId = `${editingData.styleId || ''}`.trim();
    const estateId = `${editingData.estateId || ''}`.trim();
    const baseOffStyleId = `${editingData.baseOffStyleId || ''}`.trim();
    const address = editingData.address || {};

    if (clientId === '') {
      errors.clientId = ['Client is required.'];
    }
    if (styleId === '') {
      errors.styleId = ['Style is required.'];
    }
    if (estateId === '') {
      errors.styleId = ['Estate is required.'];
    }

    if (
      editingData?.style &&
      editingData.style.isCustomised === true &&
      baseOffStyleId === ''
    ) {
      errors.baseOffStyleId = ['Base Off Style is required.'];
    }

    ['street', 'suburb', 'state', 'postcode'].forEach((fieldName) => {
      const content =
        `${fieldName in address ? address[fieldName] : ''}`.trim();
      if (content === '') {
        errors[fieldName] = [`${fieldName} is required.`];
      }
    });

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doCreate = async () => {
    const address = await AddressService.create(editingData.address);
    const baseOffStyleId = `${editingData.baseOffStyleId || ''}`.trim();
    return BuildService.create({
      clientId: editingData.clientId,
      addressId: address.id,
      styleId: editingData.styleId,
      estateId: editingData.estateId,
      ...(baseOffStyleId === '' ? {} : { baseOffStyleId }),
    });
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    if (preSave() !== true) {
      return;
    }

    setIsSaving(true);
    doCreate()
      .then((resp) => {
        handleClose(setShowingModal);
        onCreated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <PopupBtn
      titleId={'build-create-popup'}
      testId={'build-create-popup-btn'}
      shouldFitContainer
      {...props}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: `Creating a new build job`,
        onClose: () => handleClose(setShowingModal),
        body: (
          <>
            <ContactCompanySelector
              testId={'client-selector'}
              label={'Client'}
              isRequired={true}
              {...getErrorProps({ error: errorMap, fieldName: 'clientId' })}
              contactCompanyType={ContactCompanyTypes.CLIENT}
              value={editingData.clientId}
              onChange={(option: iOptionWithData<iContactCompany> | null) => {
                setEditingData({
                  ...editingData,
                  clientId: option?.value,
                });
              }}
            />
            <AddressEditPanel
              testId={'new-address'}
              address={editingData.address}
              useAsForm={{
                errorMap,
                onFieldChange: (fieldName, value) => {
                  setEditingData({
                    ...editingData,
                    address: {
                      ...editingData.address,
                      [fieldName]: value,
                    },
                  });
                },
              }}
            />
            <EstateSelector
              isRequired
              label={'Estate'}
              testId={'new-estateId'}
              value={editingData?.estateId}
              onChange={(selected: iOption) => {
                setEditingData({
                  ...editingData,
                  estate: selected?.data,
                  estateId: selected?.value,
                });
              }}
            />
            <BuildStyleSelector
              isRequired
              label={'Style'}
              testId={'new-styleId'}
              {...getErrorProps({ error: errorMap, fieldName: 'styleId' })}
              value={editingData?.styleId}
              onChange={(selected: iOption) => {
                setEditingData({
                  ...editingData,
                  style: selected?.data,
                  styleId: selected?.value,
                });
              }}
            />
            {editingData.style?.isCustomised !== true ? null : (
              <BuildStyleSelector
                isRequired
                noneCustomOnly
                label={'Base Off Style'}
                testId={'new-baseOffStyleId'}
                {...getErrorProps({
                  error: errorMap,
                  fieldName: 'baseOffStyleId',
                })}
                value={editingData?.baseOffStyleId}
                onChange={(selected: iOption) => {
                  setEditingData({
                    ...editingData,
                    baseOffStyleId: selected?.value,
                  });
                }}
              />
            )}
          </>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Create',
            testId: `${props.testId || ''}-createBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default BuildCreatePopupBtn;
