import InlineEdit, { iInlineEdit } from '../frameWork/InlineEdit';
import * as _ from 'lodash';
import iBuild from '../../types/build/iBuild';
import iBuildStyle from '../../types/build/iBuildStyle';
import StringHelper from '../../helpers/StringHelper';

export type iBuildSizeInput = iInlineEdit & {
  entity: iBuild | iBuildStyle;
  fieldName: string;
};
const BuildSizeInput = ({ entity, fieldName, ...props }: iBuildSizeInput) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const value = fieldName in entity ? entity[fieldName] : '';
  const valStr = `${value || ''}`.trim();

  return (
    <InlineEdit
      {...props}
      placeHolder={'input the size in m2'}
      label={
        props.label || (
          <>
            {_.capitalize(fieldName.toLowerCase().replace('size', ''))} Size (m
            <sup>2</sup>)
          </>
        )
      }
      value={valStr}
      editViewProps={{
        elemAfterInput: (
          <div className={'input-post'}>
            m<sup>2</sup>
          </div>
        ),
      }}
      validate={StringHelper.validateSize}
    />
  );
};

export default BuildSizeInput;
