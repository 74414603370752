import { default as Origin, TextFieldProps } from '@atlaskit/textfield';
import React from 'react';
import FormField, { iFormField } from './FormField';

export type iTextField = Omit<iFormField<TextFieldProps>, 'render'>;
export type iTextFieldValue = string | number | readonly string[] | undefined;

const TextField = (props: iTextField) => {
  return (
    <FormField<TextFieldProps>
      {...props}
      render={(fProps) => (
        <Origin {...fProps} testId={`${fProps.testId || ''}-textField`} />
      )}
    />
  );
};

export default TextField;
