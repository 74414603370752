import EntityNames from '../../../helpers/EntityNames';
import ComponentWrapper from '../../frameWork/ComponentWrapper';
import BuildSizeInput, { iBuildSizeInput } from '../../build/BuildSizeInput';
import { useEffect, useState } from 'react';
import iBuildStyle from '../../../types/build/iBuildStyle';
import iBuild from '../../../types/build/iBuild';
import BuildService from '../../../services/build/BuildService';
import BuildStyleService from '../../../services/build/BuildStyleService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../../common/Toaster';
import Spinner from '../../frameWork/Spinner';
import MathHelper from '../../../helpers/MathHelper';

type iSizeEntity = iBuild | iBuildStyle;
type iAttributeBuildSizeInput = Omit<
  iBuildSizeInput,
  'entity' | 'onConfirm'
> & {
  entityId: string;
  entityName: EntityNames.Build | EntityNames.BuildStyle;
  onSaved?: (saved: iSizeEntity) => void;
};

const AttributeBuildSizeInput = ({
  entityId,
  entityName,
  fieldName,
  onSaved,
  ...props
}: iAttributeBuildSizeInput) => {
  const [isLoading, setIsLoading] = useState(false);
  const [entity, setEntity] = useState<iSizeEntity | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [forceReload, setForceReload] = useState(1);

  useEffect(() => {
    const entityIdStr = `${entityId || ''}`.trim();
    if (entityIdStr === '') {
      setEntity(null);
      return;
    }
    const getData = () =>
      entityName === EntityNames.Build
        ? BuildService.get(entityIdStr)
        : BuildStyleService.get(entityIdStr);
    let isCancelled = false;
    setIsLoading(true);
    getData()
      .then((resp) => {
        if (isCancelled) {
          return;
        }
        setEntity(resp);
      })
      .catch((err) => {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [entityId, entityName, forceReload]);

  const handleSave = (newValue: string) => {
    const newValueStr = `${newValue || ''}`.trim();
    if (
      !entity ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      `${entity[fieldName] || ''}`.trim() === newValueStr
    ) {
      return;
    }
    const data = { [fieldName]: newValueStr === '' ? null : newValueStr };
    const savingFn = () =>
      entityName === EntityNames.Build
        ? BuildService.update(entity.id, data)
        : BuildStyleService.update(entity.id, data);
    setIsSaving(true);
    savingFn()
      .then((resp) => {
        setIsSaving(false);
        Toaster.showToast(
          `${fieldName} updated to ${newValue}`,
          TOAST_TYPE_SUCCESS,
        );
        setForceReload(MathHelper.add(forceReload, 1));
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!entity || !(fieldName in entity)) {
      return null;
    }

    return (
      <BuildSizeInput
        entity={entity}
        fieldName={fieldName}
        {...props}
        isDisabled={isSaving || props.isDisabled}
        onConfirm={handleSave}
      />
    );
  };

  return (
    <ComponentWrapper componentName={'AttributeBuildSizeInput'}>
      {getContent()}
    </ComponentWrapper>
  );
};

export default AttributeBuildSizeInput;
