import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iBuild from '../../types/build/iBuild';
import InlineEdit, { ViewWrapper } from '../frameWork/InlineEdit';
import StringHelper from '../../helpers/StringHelper';
import BuildService from '../../services/build/BuildService';
import { useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import styled from 'styled-components';
import Flex from '../frameWork/Flex';
import Tokens from '../frameWork/Tokens';
import Toggle from '../frameWork/Toggle';
import iBuildStyle, {
  SizeFields,
  sizeFields,
} from '../../types/build/iBuildStyle';
import EntityNames from '../../helpers/EntityNames';
import { iConfigParams } from '../../services/AppService';
import BuildStyleService from '../../services/build/BuildStyleService';
import BuildSizeInput from './BuildSizeInput';
import Spinner from '../frameWork/Spinner';
import MathHelper from '../../helpers/MathHelper';
import FormField from '../frameWork/FormField';

const Wrapper = styled.div`
  form {
    margin-top: 0px;
  }
  .input-post {
    padding: ${Tokens('space.050', '8px')};
  }
`;

export type iBuildSizeEditPanel = iComponentWithPageHeader & {
  entityId: string;
  entityName: EntityNames;
  isDisabled?: boolean;
  onSaved?: (saved: iBuild | iBuildStyle) => void;
  showingFieldNames?: string[];
  noneEmptyFieldNames?: SizeFields[];
  showTotal?: boolean;
};

const BuildSizeEditPanel = ({
  entityId,
  entityName,
  onSaved,
  isDisabled = false,
  showTotal = false,
  showingFieldNames = [],
  noneEmptyFieldNames = [],
  ...props
}: iBuildSizeEditPanel) => {
  const componentName = 'BuildSizeEditPanel';
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [forceReload, setForceReload] = useState(1);
  const [entity, setEntity] = useState<iBuild | iBuildStyle | null>(null);

  useEffect(() => {
    const entityIdStr = `${entityId || ''}`.trim();
    if (entityIdStr === '') {
      setEntity(null);
      return;
    }
    const getData = () =>
      entityName === EntityNames.Build
        ? BuildService.get(entityIdStr)
        : BuildStyleService.get(entityIdStr);
    let isCancelled = false;
    setIsLoading(true);
    getData()
      .then((resp) => {
        if (isCancelled) {
          return;
        }
        setEntity(resp);
      })
      .catch((err) => {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [entityId, entityName, forceReload]);

  const handleFieldChanged = (
    fieldName: string,
    newValue: string | boolean,
  ) => {
    if (!entity) {
      return;
    }
    const newValueStr = `${newValue || ''}`.trim();
    const newData: iConfigParams = {
      [fieldName]:
        newValue === true
          ? true
          : newValue === false
            ? false
            : newValueStr === ''
              ? null
              : newValueStr,
    };
    const savingFn =
      entityName === EntityNames.Build
        ? BuildService.update
        : entityName === EntityNames.BuildStyle
          ? BuildStyleService.update
          : null;
    if (!savingFn) {
      return;
    }
    setIsSaving(true);
    savingFn(entity.id, newData)
      .then((resp) => {
        setIsSaving(false);
        setForceReload(MathHelper.add(forceReload, 1));
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const showField = (fieldName: string) => {
    if (showingFieldNames.length > 0) {
      return showingFieldNames.includes(fieldName);
    }
    return true;
  };

  const getBuildSizeInputs = () => {
    if (entityName !== EntityNames.Build) {
      return null;
    }
    const editingEntity = entity as iBuild;
    return (
      <>
        {showField('isCornerBlock') && (
          <Toggle
            label={'Is Corner Block?'}
            isDisabled={isDisabled}
            separatedLines
            testId={'is-corner'}
            isChecked={editingEntity.isCornerBlock === true}
            onChange={(event) =>
              handleFieldChanged('isCornerBlock', event.target.checked)
            }
          />
        )}
        {showField('blockSize') && (
          <InlineEdit
            readViewFitContainerWidth
            placeHolder={'input the size in m2'}
            testId={'blockSize'}
            onConfirm={(newValue) => handleFieldChanged('blockSize', newValue)}
            isDisabled={isSaving || isDisabled}
            validate={StringHelper.validateSize}
            label={
              <>
                Block Size (m<sup>2</sup>)
              </>
            }
            value={`${editingEntity.blockSize || ''}`.trim()}
            editViewProps={{
              elemAfterInput: (
                <div className={'input-post'}>
                  m<sup>2</sup>
                </div>
              ),
            }}
          />
        )}
      </>
    );
  };

  const getFilteredFields = () => {
    return sizeFields.filter(showField).filter((fieldName) => {
      if (noneEmptyFieldNames?.length <= 0) {
        return true;
      }
      if (noneEmptyFieldNames?.indexOf(fieldName) < 0) {
        return false;
      }

      const entityObj = entity || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const value = fieldName in entityObj ? entityObj[fieldName] : '';
      const valStr = `${value || ''}`.trim();
      if (valStr === '') {
        return false;
      }
      return true;
    });
  };

  const getTotalDiv = () => {
    if (showTotal !== true) {
      return null;
    }
    const total = getFilteredFields().reduce((sum, fieldName) => {
      const entityObj = entity || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const value = fieldName in entityObj ? entityObj[fieldName] : '';
      const valStr = `${value || ''}`.trim();
      return MathHelper.add(sum, valStr === '' ? 0 : parseFloat(valStr));
    }, 0);
    return (
      <FormField
        label={
          <>
            TOTAL (m<sup>2</sup>)
          </>
        }
        render={() => (
          <ViewWrapper className={'none-pointer'}>{total}</ViewWrapper>
        )}
      />
    );
  };

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (!entity) {
      return null;
    }
    return (
      <Flex className={'gap-05 align-items-start flex-wrap'}>
        {getBuildSizeInputs()}
        {showField('houseSizeInSquare') && (
          <InlineEdit
            readViewFitContainerWidth
            testId={'houseSizeInSquare'}
            placeHolder={'input the size in square'}
            value={`${entity.houseSizeInSquare || ''}`.trim()}
            onConfirm={(newValue) =>
              handleFieldChanged('houseSizeInSquare', newValue)
            }
            isDisabled={isSaving || isDisabled}
            label={<>House Size in Square</>}
            validate={StringHelper.validateSize}
          />
        )}
        {getFilteredFields().map((fieldName) => {
          return (
            <BuildSizeInput
              onConfirm={(newValue) => handleFieldChanged(fieldName, newValue)}
              entity={entity}
              fieldName={fieldName}
              key={`${componentName}-${entityName}-${entity.id}-${fieldName}`}
              testId={fieldName}
              isDisabled={isSaving || isDisabled}
            />
          );
        })}
        {getTotalDiv()}
      </Flex>
    );
  };

  return (
    <Wrapper>
      <ComponentWithPageHeader {...props}>
        {getContent()}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default BuildSizeEditPanel;
