import React, { useState } from 'react';
import styled from 'styled-components';
import Popup from '../frameWork/Popup';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';

const PopupWrapper = styled.div`
  .popup-open {
    background-color: rgb(37, 56, 88);
    color: rgb(244, 245, 247) !important;
  }
`;
const MoreIconPopup = ({
  children,
  testId,
}: {
  children: React.ReactNode;
  testId?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PopupWrapper>
      <Popup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={() => children}
        trigger={(triggerProps) => (
          <IconButton
            //    eslint-disable-next-line
            {...triggerProps}
            id="popup-trigger"
            // className={isOpen ? 'popup-open' : ''}
            onClick={() => setIsOpen(!isOpen)}
            icon={(iconProps) => (
              <Icons.EditorMoreIcon
                {...iconProps}
                size={'medium'}
                testId="editor-more-icon"
              />
            )}
            testId={testId || 'popup-trigger-btn'}
            label={'more'}
          />
        )}
        placement={'bottom-end'}
        autoFocus
      />
    </PopupWrapper>
  );
};

export default MoreIconPopup;
