import iBuild from '../../../../../types/build/iBuild';
import styled from 'styled-components';
import { DatePicker, formatDate } from '../../../../frameWork/DateTimePicker';
import Flex from '../../../../frameWork/Flex';
import AttributeSetInputPanel from '../../../../attributeSet/AttributeSetInputPanel';
import { AttributeForSections } from '../../../../../types/attribute/iAttribute';
import EntityNames from '../../../../../helpers/EntityNames';
import { AttributeSetCodes } from '../../../../../types/attribute/iAttributeSet';
import { iConfigParams } from '../../../../../services/AppService';
import { useState } from 'react';
import BuildService from '../../../../../services/build/BuildService';
import Toaster from '../../../../common/Toaster';

const Wrapper = styled.div``;
type iBuildDetailsPrelimOverview = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsPrelimOverview = ({
  build,
  isDisabled,
  onBuildUpdated,
}: iBuildDetailsPrelimOverview) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (newValues: iConfigParams) => {
    setIsSaving(true);
    BuildService.update(build.id, newValues)
      .then((resp) => {
        onBuildUpdated && onBuildUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Wrapper>
      <Flex className={'gap-05 flex-wrap'}>
        {[
          {
            label: 'Contracts Ordered',
            value: build.contractOrderDate,
            fieldName: 'contractOrderDate',
          },
          {
            label: 'Contracts Produced',
            value: build.contractProducedDate,
            fieldName: 'contractProducedDate',
          },
          {
            label: 'Contracts Done',
            value: build.contractDoneDate,
            fieldName: 'contractDoneDate',
          },
          {
            label: 'Contracts Signed & Returned',
            value: build.contractSignedDate,
            fieldName: 'contractSignedDate',
          },
        ].map((item) => (
          <DatePicker
            appearance={'subtle'}
            key={item.fieldName}
            label={item.label}
            isDisabled={isDisabled || isSaving}
            value={formatDate(item.value)}
            onChange={(value: string) =>
              handleUpdate({ [item.fieldName]: value })
            }
          />
        ))}
      </Flex>
      <AttributeSetInputPanel
        isDisabled={isDisabled || isSaving}
        attributeSection={AttributeForSections.PRELIM}
        entityId={build.id}
        entityName={EntityNames.Build}
        attributeSetCodes={[
          AttributeSetCodes.PropertyTitle,
          AttributeSetCodes.SoilTest,
          AttributeSetCodes.Site,
          AttributeSetCodes.Slab,
          AttributeSetCodes.SlabAngleRepose,
          AttributeSetCodes.RetainingWall,
        ]}
      />
    </Wrapper>
  );
};

export default BuildDetailsPrelimOverview;
