import ServiceHelper from '../helper/ServiceHelper';
import iBuildPackBuild from '../../types/build/iBuildPackBuild';

const endPoint = '/buildPackBuild';
const BuildPackBuildService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildPackBuild>(endPoint),
  get: ServiceHelper.getById<iBuildPackBuild>(endPoint),
  create: ServiceHelper.create<iBuildPackBuild>(endPoint),
  update: ServiceHelper.updateById<iBuildPackBuild>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildPackBuild>(endPoint),
};

export default BuildPackBuildService;
