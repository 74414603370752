import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import Toaster from '../common/Toaster';
import Spinner from '@atlaskit/spinner';
import { AttributeForSections } from '../../types/attribute/iAttribute';
import AttributeValueEditSingleItem from '../attribute/AttributeValueEditSingleItem';
import EntityNames from '../../helpers/EntityNames';
import AttributeItemEditTable from '../attribute/AttributeItemEditTable';
import * as _ from 'lodash';
import Tokens from '../frameWork/Tokens';

const Wrapper = styled.div`
  .attribute-value-single-set,
  .AttributeValueEditTable {
    margin-bottom: ${Tokens('space.400', '1rem')};
  }
`;

export type iAttributeSetInputPanel = {
  testId?: string;
  className?: string;
  attributeSetCodes: AttributeSetCodes[];
  attributeSection: AttributeForSections;
  entityId: string;
  entityName: EntityNames;
  isDisabled?: boolean;
};
type iAttributeSetMap = { [key: string]: iAttributeSet };
const AttributeSetInputPanel = ({
  testId,
  className,
  entityId,
  entityName,
  attributeSection,
  attributeSetCodes,
  isDisabled = false,
}: iAttributeSetInputPanel) => {
  const componentName = 'AttributeSetInputPanel';
  const testIdStr = `${testId || ''}`;
  const [attributeSetMap, setAttributeSetMap] = useState<iAttributeSetMap>({});
  const [isLoading, setIsLoading] = useState(false);
  const [reloadingAttributeSetCode, setReloadingAttributeSetCode] =
    useState<AttributeSetCodes | null>(null);

  useEffect(() => {
    if (attributeSetCodes.length <= 0) {
      setAttributeSetMap({});
      return;
    }

    let isCanceled = false;
    setIsLoading(true);
    AttributeSetService.getAll({
      where: JSON.stringify({
        code: attributeSetCodes,
        isActive: true,
      }),
      perPage: attributeSetCodes.length,
    })
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setAttributeSetMap(
          (resp.data || []).reduce(
            (map, attributeSet) => ({
              ...map,
              [attributeSet.code]: attributeSet,
            }),
            {},
          ),
        );
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [attributeSetCodes]);

  const refreshingAttributeSet = (attributeSetCode: AttributeSetCodes) => {
    setReloadingAttributeSetCode(attributeSetCode);
    AttributeSetService.getAll({
      where: JSON.stringify({
        code: attributeSetCode,
        isActive: true,
      }),
      perPage: 1,
    })
      .then((resp) => {
        const data = resp.data || [];
        if (data.length <= 0) {
          return;
        }
        setAttributeSetMap({
          ...attributeSetMap,
          [data[0].code]: data[0],
        });
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setReloadingAttributeSetCode(null);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (Object.keys(attributeSetMap).length <= 0) {
    return null;
  }

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      {_.uniq(attributeSetCodes).map((attributeSetCode) => {
        if (!(attributeSetCode in attributeSetMap)) {
          return null;
        }
        if (reloadingAttributeSetCode === attributeSetCode) {
          return <Spinner key={reloadingAttributeSetCode} />;
        }
        const attributeSet = attributeSetMap[attributeSetCode];
        const key = `${attributeSection}-${attributeSetCode}-${entityName}-${entityId}`;
        if (attributeSet.settings?.isMulti !== true) {
          return (
            <AttributeValueEditSingleItem
              key={key}
              attributeSetCode={attributeSetCode}
              entityId={entityId}
              entityName={entityName}
              attributeSection={attributeSection}
              providedAttrSet={attributeSet}
              isDisabled={isDisabled}
              onRefreshBtnClicked={() =>
                refreshingAttributeSet(attributeSetCode)
              }
            />
          );
        }
        return (
          <AttributeItemEditTable
            key={key}
            attributeSetCode={attributeSetCode}
            entityId={entityId}
            entityName={entityName}
            attributeSection={attributeSection}
            providedAttrSet={attributeSet}
            allowCreate={!isDisabled}
            allowEdit={!isDisabled}
            allowDelete={!isDisabled}
            onRefreshBtnClicked={() => refreshingAttributeSet(attributeSetCode)}
          />
        );
      })}
    </Wrapper>
  );
};

export default AttributeSetInputPanel;
