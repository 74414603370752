import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../../helpers/DynamicTableHelper';
import StringHelper from '../../../helpers/StringHelper';
import ContactCompanyProductService from '../../../services/contactCompanyProduct/ContactCompanyProductService';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../../types/contact/iContactCompany';
import iContactCompanyProduct from '../../../types/contact/iContactCompanyProduct';
import iProduct from '../../../types/product/iProduct';
import iUnitOfMeasure from '../../../types/unitOfMeasure/iUnitOfMeasure';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../../common/PageTitleWithCreateBtn';
import ContactCompanySelector from '../../contact/ContactCompanySelector';
import { Conversion } from '../../contactCompanyProduct/Conversion';
import ConversionText from '../../contactCompanyProduct/ConversionText';
import Select, { iOptionWithData } from '../../frameWork/Select';
import useListCrudHook from '../../hooks/useListCrudHook/useListCrudHook';
import UnitOfMeasureSelector from '../../unitOfMeasure/UnitOfMeasureSelector';

type iListProps = iComponentWithPageHeader & {
  allowDelete?: boolean;
  product?: iProduct;
};

type iUnitPriceMeasureOption = {
  label: string;
  value: string;
};

const ProductDetailsSuppliersPanel = ({
  allowDelete,
  headerProps,
  product,
}: iListProps) => {
  const { state, renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iContactCompanyProduct>({
      getFn: (params) =>
        ContactCompanyProductService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
            productId: product?.id,
          }),
          include:
            'CreatedBy,UpdatedBy,ContactCompany,Measurement,UnitPriceMeasurement',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getUnitPriceMeasureOptions = (entity: iContactCompanyProduct) => {
    let options: Array<iUnitPriceMeasureOption> = [];
    if (
      product &&
      product.UOM &&
      !options.find((option) => option.value === product.uomId)
    ) {
      options = [
        ...options,
        {
          label: product.UOM.name,
          value: product.uomId || '',
        },
      ];
    }
    if (
      entity &&
      entity.Measurement &&
      !options.find((option) => option.value === entity.measurementId)
    ) {
      options = [
        ...options,
        {
          label: entity.Measurement.name,
          value: entity.measurementId,
        },
      ];
    }
    return options;
  };

  const getEditBtn = (item?: iContactCompanyProduct) => {
    return renderEntityEditPopBtn<iContactCompanyProduct>({
      editingEntity: item,
      entityName: 'ContactCompanyProduct',
      createFn: (data) =>
        ContactCompanyProductService.create({
          ...data,
          productId: product?.id,
        }),
      updateFn: (id, data) =>
        ContactCompanyProductService.update(id, {
          ...data,
          productId: product?.id,
        }),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`supplier-${entity?.id || ''}`}
        >
          {item?.ContactCompany?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'contactCompanyId',
          label: 'Supplier',
          isDisabled,
          isRequired: true,
          value: entity?.contactCompanyId || '',
          testId: 'ContactCompanyProduct-contactCompanyId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <ContactCompanySelector
                {...props}
                {...errorProps}
                contactCompanyType={ContactCompanyTypes.SUPPLIER}
                onChange={(option: iOptionWithData<iContactCompany> | null) =>
                  useAsForm.onFieldChange(
                    'contactCompanyId',
                    option?.value || '',
                  )
                }
              />
            );
          },
        },
        {
          fieldName: 'measurementId',
          label: 'Supplier Unit',
          isDisabled,
          isRequired: true,
          value: entity?.measurementId || '',
          testId: 'ContactCompanyProduct-measurementId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <UnitOfMeasureSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iUnitOfMeasure> | null) => {
                  useAsForm.onFieldChange('measurementId', option?.value || '');
                  useAsForm.onFieldChange('Measurement', option?.data || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'unitConversion',
          label: 'Conversion',
          isDisabled,
          isRequired: true,
          value: entity?.unitConversion || '',
          testId: 'ContactCompanyProduct-unitConversion',
          isWatching: true,
          isValid: (value: string) => {
            const valStr = `${value || ''}`.trim();
            if (valStr === '') {
              return {
                isValid: false,
                errMessages: ['Conversion is required'],
              };
            }
            if (!StringHelper.isNumeric(valStr)) {
              return {
                isValid: false,
                errMessages: ['Conversion needs to be numeric'],
              };
            }
            return { isValid: true, errMessages: [] };
          },
          renderComponent: (props, useAsForm, errorProps, entity) => {
            return (
              <Conversion
                props={props}
                useAsForm={useAsForm}
                errorProps={errorProps}
                contactCompanyProduct={entity}
                product={product}
              />
            );
          },
        },
        {
          fieldName: 'code',
          label: 'Supplier Product Code/SKU',
          isDisabled,
          isRequired: true,
          value: entity?.code || '',
          testId: 'ContactCompanyProduct-code',
        },
        {
          fieldName: 'unitPrice',
          label: 'Unit Price',
          isDisabled,
          isRequired: true,
          value: entity?.unitPrice || '',
          testId: 'ContactCompanyProduct-unitPrice',
          isValid: (value: string) => {
            const valStr = `${value || ''}`.trim();
            if (valStr === '') {
              return {
                isValid: false,
                errMessages: ['Unit Price is required'],
              };
            }
            if (!StringHelper.isNumeric(valStr)) {
              return {
                isValid: false,
                errMessages: ['Unit Price needs to be numeric'],
              };
            }
            return { isValid: true, errMessages: [] };
          },
        },
        {
          fieldName: 'unitPriceMeasurementId',
          label: 'Unit Price Measurement',
          isDisabled,
          isRequired: true,
          value: entity?.unitPriceMeasurementId || '',
          testId: 'ContactCompanyProduct-unitPriceMeasurementId',
          isWatching: true,
          renderComponent: (props, useAsForm, errorProps, entity) => {
            const options = getUnitPriceMeasureOptions(entity);
            return (
              <Select
                {...props}
                {...errorProps}
                value={options.find(
                  (option) => option.value === entity?.unitPriceMeasurementId,
                )}
                options={options}
                onChange={(option) =>
                  useAsForm.onFieldChange(
                    'unitPriceMeasurementId',
                    option?.value || '',
                  )
                }
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iContactCompanyProduct>[] => [
    {
      key: 'contactCompany',
      header: 'Supplier',
      cell: ({ data }: iCellParams<iContactCompanyProduct>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'code',
      header: 'Supplier Product Code/SKU',
      cell: ({ data }: iCellParams<iContactCompanyProduct>) =>
        `${data.code || ''}`,
    },
    {
      key: 'unitPrice',
      header: 'Unit Price',
      cell: ({ data }: iCellParams<iContactCompanyProduct>) =>
        `${data.unitPrice} / ${data.UnitPriceMeasurement?.name}`,
    },
    {
      key: 'unitPriceMeasurement',
      header: 'Supplier Unit',
      cell: ({ data }: iCellParams<iContactCompanyProduct>) =>
        `${data.UnitPriceMeasurement?.name}`,
    },
    {
      key: 'unitConversion',
      header: 'Conversion',
      cell: ({ data }: iCellParams<iContactCompanyProduct>) => (
        <ConversionText
          value={1}
          contactCompanyUnit={data.Measurement?.name || ''}
          conversion={Number(data.unitConversion)}
          productUnit={product?.UOM?.name || ''}
        />
      ),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iContactCompanyProduct>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iContactCompanyProduct>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      ContactCompanyProductService.deactivate(data.id),
                    getDisplayName: (item) => item.ContactCompany?.name || '',
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={`${state.data.total} supplier(s) for this product`}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'ContactCompanyProduct-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default ProductDetailsSuppliersPanel;
