import SettingsPage from '../../layouts/SettingsPage';
import AttributeSetList from '../../components/attributeSet/AttributeSetList';

const AttributeSetsPage = () => {
  const params = new URLSearchParams(window.location.search);
  const enableAdmin = params.get('enableAdmin') === 'true';
  return (
    <SettingsPage title={'Attribute Sets'}>
      {(headerProps) => (
        <AttributeSetList
          headerProps={headerProps}
          allowDelete={enableAdmin}
          allowCreate={enableAdmin}
        />
      )}
    </SettingsPage>
  );
};

export default AttributeSetsPage;
