import ServiceHelper from '../helper/ServiceHelper';
import iBuildPack from '../../types/build/iBuildPack';

const endPoint = '/buildPack';
const BuildPackService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildPack>(endPoint),
  get: ServiceHelper.getById<iBuildPack>(endPoint),
  create: ServiceHelper.create<iBuildPack>(endPoint),
  update: ServiceHelper.updateById<iBuildPack>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildPack>(endPoint),
};

export default BuildPackService;
