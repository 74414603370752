import { AttributeTypes } from '../../types/attribute/iAttribute';
import Select, { iOption, iSelect } from '../frameWork/Select';

export type iAttributeInputTypeSelector = Omit<
  iSelect<AttributeTypes>,
  'options' | 'value' | 'values'
> & {
  value?: AttributeTypes | null;
  values?: AttributeTypes[] | null;
};
const AttributeInputTypeSelector = ({
  value,
  values,
  ...props
}: iAttributeInputTypeSelector) => {
  const availOptions = Object.values(
    Object.values(AttributeTypes)
      .map((type) => ({
        label: type,
        value: type,
        group:
          [
            AttributeTypes.FILE_UPLOADER,
            AttributeTypes.BUIlD_SIZE_CHANGER,
            AttributeTypes.ATTRIBUTE_ALIAS,
            AttributeTypes.SUPPLIER_DROPDOWN,
            AttributeTypes.PRODUCT_DROPDOWN,
            AttributeTypes.HOUSE_AREA_DROPDOWN,
          ].indexOf(type) >= 0
            ? 'Advanced'
            : 'Inputs',
      }))
      .reduce((map, item) => {
        const previousOptions =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          (item.group in map && map[item.group].options) || [];
        return {
          ...map,
          [item.group]: {
            label: item.group,
            options: [
              ...previousOptions,
              { label: item.label, value: item.value },
            ],
          },
        };
      }, {}),
  );

  const getHelperText = (data: iOption) => {
    switch (data.value) {
      case AttributeTypes.FILE_UPLOADER: {
        return 'View or upload a file as value of this attribute.';
      }
      case AttributeTypes.PRODUCT_DROPDOWN: {
        return 'View or change a product as the value of this attribute.';
      }
      case AttributeTypes.SUPPLIER_DROPDOWN: {
        return 'View or change a Supplier as the value of this attribute.';
      }
      case AttributeTypes.HOUSE_AREA_DROPDOWN: {
        return 'View or change a HouseArea as the value of this attribute.';
      }
      case AttributeTypes.BUIlD_SIZE_CHANGER: {
        return 'View or change the size of a Build of BuildStyle.';
      }
      case AttributeTypes.ATTRIBUTE_ALIAS: {
        return `CAUTION! View or change another attribute's value.`;
      }
      default: {
        return '';
      }
    }
  };

  const translateOption = (data: iOption) => {
    const label = `${data.value || ''}`.trim().replace(/_/g, ' ').toUpperCase();
    const helperMsg = getHelperText(data);

    return (
      <>
        {label}{' '}
        {helperMsg !== '' && (
          <div>
            <small className={'text-italic'}>{helperMsg}</small>
          </div>
        )}
      </>
    );
  };

  const getSelectedValue = (val?: AttributeTypes | null) => {
    const valueStr = `${val || ''}`.trim();
    if (valueStr === '') {
      return undefined;
    }
    return { label: valueStr, value: valueStr };
  };

  const getSelectedValues = (vals?: AttributeTypes[] | null) => {
    const selectedVals = vals || [];
    if (selectedVals.length <= 0) {
      return undefined;
    }
    return selectedVals.map((value) => getSelectedValue(value));
  };
  return (
    <Select
      {...props}
      formatOptionLabel={translateOption}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      options={availOptions}
      value={getSelectedValue(value)}
      values={getSelectedValues(values)}
    />
  );
};

export default AttributeInputTypeSelector;
