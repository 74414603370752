import EntityNames from '../../helpers/EntityNames';
import styled from 'styled-components';
import HouseStoreySelector from './HouseStoreySelector';
import iBuild from '../../types/build/iBuild';
import iBuildStyle from '../../types/build/iBuildStyle';
import { useEffect, useState } from 'react';
import BuildService from '../../services/build/BuildService';
import BuildStyleService from '../../services/build/BuildStyleService';
import Toaster from '../common/Toaster';
import Spinner from '../frameWork/Spinner';
import { iOption } from '../frameWork/Select';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import BuildStoreyHeightService from '../../services/build/BuildStoreyHeightService';
import iBuildStoreyHeight from '../../types/build/iBuildStoreyHeight';
import * as _ from 'lodash';
import InlineEdit from '../frameWork/InlineEdit';
import Flex from '../frameWork/Flex';
import StringHelper from '../../helpers/StringHelper';
import MathHelper from '../../helpers/MathHelper';

const Wrapper = styled.div`
  form {
    margin-top: 0px;
  }
`;

type iBuildStoreyHeightEntity = iBuild | iBuildStyle;
export type iBuildStoreyHeightsEditPanel = iComponentWithPageHeader & {
  entityName: EntityNames;
  entityId: string;
  className?: string;
  testId?: string;
  onEntityUpdated?: (saved: iBuildStoreyHeightEntity) => void;
  onHeightSaved?: (saved: iBuildStoreyHeight, isCreated: boolean) => void;
  isDisabled?: boolean;
  showStoreySelector?: boolean;
};
const BuildStoreyHeightsEditPanel = ({
  entityName,
  entityId,
  testId,
  className,
  isDisabled = false,
  showStoreySelector = true,
  headerProps,
  onEntityUpdated,
  onHeightSaved,
  ...props
}: iBuildStoreyHeightsEditPanel) => {
  const componentName = 'BuildStoreyHeightsEditPanel';
  const testIdStr = `${testId || ''}-BuildStoreyHeightsEditPanel-${entityName}-${entityId}`;
  const [entity, setEntity] = useState<iBuildStoreyHeightEntity | null>(null);
  const [buildStoreyHeights, setBuildStoreyHeights] = useState<
    iBuildStoreyHeight[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let isCanceled = false;

    const include = {
      include: 'Storey',
    };
    const getData =
      entityName === EntityNames.Build
        ? () => BuildService.get(entityId, include)
        : entityName === EntityNames.BuildStyle
          ? () => BuildStyleService.get(entityId, include)
          : null;
    if (getData === null) {
      return;
    }
    setIsLoading(true);
    Promise.all([
      getData(),
      BuildStoreyHeightService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityName,
          entityId,
        }),
        perPage: 999999,
      }),
    ])
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setEntity(resp[0]);
        setBuildStoreyHeights(resp[1].data || []);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [entityName, entityId, count]);

  const handleStoreyChanged = (selected: iOption) => {
    const data = {
      storeyId: selected.value,
    };
    const updateData =
      entityName === EntityNames.Build
        ? () => BuildService.update(entityId, data)
        : entityName === EntityNames.BuildStyle
          ? () => BuildStyleService.update(entityId, data)
          : null;
    if (updateData === null) {
      return;
    }
    setIsSaving(true);
    const deactivatingHeightIds = buildStoreyHeights
      .filter(
        (buildStoreyHeight) =>
          buildStoreyHeight.level >= selected.data.noOfLevels || 0,
      )
      .map((buildStoreyHeight) => buildStoreyHeight.id);
    Promise.all([
      updateData(),
      ...deactivatingHeightIds.map((deactivatingHeightId) =>
        BuildStoreyHeightService.deactivate(deactivatingHeightId),
      ),
    ])
      .then((resp) => {
        setCount(MathHelper.add(count, 1));
        onEntityUpdated && onEntityUpdated(resp[0]);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleHeightChanged = (
    level: number,
    newHeight: string,
    buildStoreyHeight?: iBuildStoreyHeight | null,
  ) => {
    const data = {
      height: newHeight,
    };
    const buildStoreyHeightId = `${buildStoreyHeight?.id || ''}`.trim();
    const saveHeightFn = () =>
      buildStoreyHeightId === ''
        ? BuildStoreyHeightService.create({
            ...data,
            level,
            entityName,
            entityId,
          })
        : BuildStoreyHeightService.update(buildStoreyHeightId, data);
    setIsSaving(true);
    saveHeightFn()
      .then((resp) => {
        setCount(MathHelper.add(count, 1));
        onHeightSaved && onHeightSaved(resp, buildStoreyHeightId === '');
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getContent = () => {
    if (isLoading === true || !entity) {
      return <Spinner />;
    }
    return (
      <>
        {showStoreySelector === true && (
          <HouseStoreySelector
            testId={`${testIdStr}-storey-selector`}
            isDisabled={isDisabled}
            isLoading={isSaving}
            label={'Storey'}
            onChange={handleStoreyChanged}
            value={entity.storeyId}
          />
        )}
        <Flex
          className={'gap-1 align-items-start flex-wrap heights-input-wrapper'}
        >
          {_.range(0, entity?.Storey?.noOfLevels || 0).map((level) => {
            const filteredHeights = buildStoreyHeights.filter(
              (buildStoreyHeight) => buildStoreyHeight.level === level,
            );
            const buildStoreyHeight =
              filteredHeights.length > 0 ? filteredHeights[0] : null;
            return (
              <InlineEdit
                key={level}
                testId={`height-${level}`}
                isDisabled={isDisabled}
                label={
                  level === 0
                    ? 'Ground Height (m)'
                    : `Level ${level} Height (m)`
                }
                value={`${buildStoreyHeight?.height || ''}`.trim()}
                placeHolder={'height in meters'}
                validate={(newValue) => {
                  const newValueStr = `${newValue || ''}`.trim();
                  if (
                    newValueStr !== '' &&
                    !StringHelper.isNumeric(`${newValue || ''}`)
                  ) {
                    return 'Height needs to be a number';
                  }
                  return '';
                }}
                onConfirm={(newValue) => {
                  handleHeightChanged(level, newValue, buildStoreyHeight);
                }}
              />
            );
          })}
        </Flex>
      </>
    );
  };
  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <ComponentWithPageHeader {...props} headerProps={headerProps}>
        {getContent()}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default BuildStoreyHeightsEditPanel;
