import ServiceHelper from '../helper/ServiceHelper';
import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';
import * as _ from 'lodash';
import AppService, { iParams } from '../AppService';

const endPoint = '/warehouseLocation';

const getHumanReadableType = (warehouseType: string) => {
  return warehouseType
    .split('_')
    .map((name) => _.capitalize(name))
    .join(' ');
};

export const getWarehousesByParentId = (parentId?: string) => {
  return AppService.get('/warehouseLocation', {
    where: JSON.stringify({ isActive: true, parentId: parentId || null }),
  }).then(({ data }) => data);
};
export const getWarehouseDetailById = (id: string) => {
  return AppService.get('/warehouseLocation', {
    include: 'facility,facility.address,createdBy,updatedBy',
    filter: `id:${id}`,
  }).then(({ data }) => data);
};
export const getWarehouseDetail = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}`, {}).then(
    ({ data }) => data,
  );
};

export const getProductsUnderWarehouse = (path: string) => {
  return AppService.get('/product', {
    include: 'warehouseLocations,measurement',
    like: `warehouseLocations.path:${path}`,
  }).then(({ data }) => data);
};

// eslint-disable-next-line
export const updateWarehouse = (id: string, updateContent: any) => {
  return AppService.put(`/warehouseLocation/${id}`, updateContent).then(
    ({ data }) => data,
  );
};

export const deactivateWarehouse = (id: string) => {
  return AppService.delete(`/warehouseLocation/${id}`).then(({ data }) => data);
};

// eslint-disable-next-line
export const createWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation', postData).then(
    ({ data }) => data,
  );
};
// eslint-disable-next-line
export const moveWarehouse = (postData: any) => {
  return AppService.post('/warehouseLocation/move', postData).then(
    ({ data }) => data,
  );
};

export const getWarehouseList = (config?: iParams) => {
  return AppService.get('/warehouseLocation', {
    ...config,
  }).then(({ data }) => data);
};

// export const getWarehouseListAsyncSearch = (config?: iParams) => {
//   return AppService.get('/warehouseLocation', {
//     ...config,
//     currentPage: ASYNC_SEARCH_CURRENT_PAGE,
//     perPage: ASYNC_SEARCH_PER_PAGE,
//   }).then(({ data }) => data);
// };

export const getWarehouseLocationPathNames = (ids: string) => {
  return AppService.get('/warehouseLocation/names', {
    ids,
  }).then(({ data }) => data);
};

export const isWIPWarehouseLocation = (id: string) => {
  return AppService.get(`/warehouseLocation/${id}/isWIP`).then(
    ({ data }) => data,
  );
};

const WarehouseService = {
  getAll: ServiceHelper.getPaginatedAll<iWarehouseLocation>(endPoint),
  get: ServiceHelper.getById<iWarehouseLocation>(endPoint),
  create: ServiceHelper.create<iWarehouseLocation>(endPoint),
  update: ServiceHelper.updateById<iWarehouseLocation>(endPoint),
  deactivate: ServiceHelper.deactivateById<iWarehouseLocation>(endPoint),
  getHumanReadableType,
  getWarehouseDetailById,
  getWarehousesByParentId,
  getProductsUnderWarehouse,
  updateWarehouse,
  deactivateWarehouse,
  // getWarehouseListAsyncSearch,
  getWarehouseDetail,
  getWarehouseList,
  getWarehouseLocationPathNames,
  moveWarehouse,
  isWIPWarehouseLocation,
  createWarehouse,
};

export default WarehouseService;
