import styled from 'styled-components';
import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';

export const DELETE_INVALID_TEXT = (
  <span>
    This warehouse owns children warehouses or has products stored there.
    <p> Please clear them up before you delete it</p>
  </span>
);
export const NOT_ALLOW_DELETE = 'Not allowed to delete it';

export const CREATE_SUB_LOCATION = 'Create a sub location';
export const CREATE_ROOT_WAREHOUSE = 'Create root warehouse';
export const MOVE_WAREHOUSE = 'Move the location';
export const PRINT_LOCATION_LABEL = 'Print location label';
export const CREATE_EDIT_FACILITY = 'Create/Edit facility';
export const EDIT_ALLOW_PART = 'Allow products or not';
export const DELETE = 'Delete';

export const ITEM_LOCATION_LIST_TITLE = 'Item Locations';
export const ITEM_LOCATION_TABLE_NAME = 'item-location-table';
export const MOVE_WARNING_MESSAGE =
  'All sub locations(if it has) will be moved as well, please make sure you want to continue';

export const ALLOW_PARTS = 'Allow product transfer in';
export const PopUpContentContainer = styled.div`
  align-items: center;
  max-height: 200px;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  vertical-align: center;
  box-sizing: border-box;
  width: min-content;
  button {
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    display: block !importany;
    width: 100%;
  }
  button:hover {
    background-color: rgb(222, 235, 255);
  }
`;
export type iWarehouseUI = iWarehouseLocation & {
  children?: Array<iWarehouseUI>;
};
