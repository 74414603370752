import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildPackService from '../../services/build/BuildPackService';
import iBuildPack from '../../types/build/iBuildPack';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { OP_LIKE } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';

type iBuildPackList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const BuildPackList = ({ allowDelete, headerProps }: iBuildPackList) => {
  const testIdStr = 'buildPacks-list';
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetFilter,
  } = useListCrudHook<iBuildPack>({
    sort: `name:ASC`,
    getFn: (params) =>
      BuildPackService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (BuildPack?: iBuildPack) => {
    return renderEntityEditPopBtn<iBuildPack>({
      testId: `${testIdStr}-create-btn`,
      editingEntity: BuildPack,
      entityName: 'BuildPack',
      createFn: (data) => BuildPackService.create(data),
      updateFn: (id, data) => BuildPackService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'description',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iBuildPack>[] => [
    {
      key: 'name',
      header: 'Name',
      isSortable: true,
      cell: ({ data }: iCellParams<iBuildPack>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'description',
      header: 'Description',
      cell: ({ data }: iCellParams<iBuildPack>) => `${data?.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildPack>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iBuildPack>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => BuildPackService.deactivate(data.id),
                    getDisplayName: (ProductType) => ProductType.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search-field`}
            placeholder={'Search name ...'}
            onSearch={(searchText) => {
              const searchTxt = `${searchText || ''}`.trim();
              onSetFilter(
                searchTxt === ''
                  ? {}
                  : { name: { [OP_LIKE]: `%${searchText}%` } },
              );
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildPackList;
