import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import TaxRateService from '../../services/tax/TaxRateService';
import iTaxRate from '../../types/tax/iTaxRate';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import StringHelper from '../../helpers/StringHelper';
import { getErrorMsgStr, hasError } from '../form/FormError';
import TextField, { iTextFieldValue } from '../frameWork/TextField';

type iTaxRateList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const TaxRateList = ({ allowDelete, headerProps }: iTaxRateList) => {
  const testIdStr = 'taxRate-list';
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iTaxRate>({
      sort: `name:ASC`,
      getFn: (params) =>
        TaxRateService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (TaxRate?: iTaxRate) => {
    return renderEntityEditPopBtn<iTaxRate>({
      editingEntity: TaxRate,
      entityName: 'TaxRate',
      createFn: (data) => TaxRateService.create(data),
      updateFn: (id, data) => TaxRateService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'TaxRate-name',
        },
        {
          fieldName: 'rate',
          label: 'Rate',
          isRequired: true,
          isDisabled,
          value: `${entity?.rate || ''}`,
          testId: 'TaxRate-rate',
          isValid: (value: string) => {
            const valStr = `${value || ''}`.trim();
            if (!StringHelper.isNumeric(valStr)) {
              return {
                isValid: false,
                errMessages: ['Rate needs to be a number'],
              };
            }
            return {
              isValid: true,
              errMessages: [],
            };
          },
          renderComponent: (props, useAsForm) => {
            return (
              <TextField
                elemAfterInput={
                  <div
                    style={{
                      paddingLeft: '0.4rem',
                      paddingRight: '0.4rem',
                    }}
                  >
                    %
                  </div>
                }
                testId={props.testId || ''}
                isRequired={props.isRequired}
                isDisabled={props.isDisabled}
                label={`${props.label} (%)`}
                value={props.value as iTextFieldValue}
                placeholder={props.label}
                isInvalid={hasError({
                  error: useAsForm.errorMap,
                  fieldName: props.fieldName,
                })}
                errorMsg={getErrorMsgStr({
                  error: useAsForm.errorMap,
                  fieldName: props.fieldName,
                })}
                onChange={(event) =>
                  useAsForm.onFieldChange(
                    props.fieldName,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    event.target.value || '',
                  )
                }
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iTaxRate>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iTaxRate>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'rate',
      header: 'Rate (%)',
      cell: ({ data }: iCellParams<iTaxRate>) => `${data?.rate || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iTaxRate>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iTaxRate>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => TaxRateService.deactivate(data.id),
                    getDisplayName: (TaxRate) => TaxRate.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default TaxRateList;
