import { default as Origin, InlineEditProps } from '@atlaskit/inline-edit';
import TextField, { iTextField } from './TextField';
import { default as React, useState } from 'react';
import { ExtendedFieldProps } from '@atlaskit/inline-edit/dist/types/types';
import styled from 'styled-components';
import Tokens from './Tokens';
import Toaster, { TOAST_TYPE_ERROR } from '../common/Toaster';

export const ViewWrapper = styled.div`
  display: flex;
  max-width: 100%;
  padding-block: ${Tokens('space.100')};
  padding-inline: ${Tokens('space.075')};
  min-width: 100px;
  line-height: 20px;
  cursor: pointer;
  // background-color: ${Tokens('color.background.accent.gray.subtlest')};
  border-radius: 4px;

  .inner-view {
    border-radius: 4px;
    width: 100%;
    padding-block: ${Tokens('space.100')};
    padding-inline: ${Tokens('space.075')};
  }

  &.none-pointer {
    cursor: auto;
  }

  .default-view,
  &.default-view {
    color: ${Tokens('color.background.accent.gray.subtlest.hovered')};
  }

  .is-invalid,
  &.is-invalid {
    background-color: ${Tokens('color.background.accent.red.subtler')};
    &:hover {
      background-color: ${Tokens(
        'color.background.accent.red.subtler.hovered',
      )};
    }
  }

  &.disabled-view {
    background-color: transparent !important;
    color: ${Tokens('color.background.accent.gray.subtlest.pressed')};
    cursor: not-allowed;
  }
`;
export type iInlineEdit = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Omit<InlineEditProps<any>, 'editView' | 'readView' | 'onConfirm'>,
  'defaultValue'
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: (newValue: any) => string; // Empty string when there is no error
  isDisabled?: boolean;
  value?: string;
  defaultValue?: string;
  placeHolder?: string;
  editView?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldProps: ExtendedFieldProps<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: React.RefObject<any>,
  ) => React.ReactNode;
  readView?: () => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (newValue: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preConfirm?: (newValue: any) => boolean;
  editViewProps?: iTextField;
};
const InlineEdit = ({
  isDisabled = false,
  editView,
  readView,
  value,
  placeHolder,
  defaultValue,
  isEditing,
  testId,
  onConfirm,
  editViewProps,
  onCancel,
  validate,
  ...props
}: iInlineEdit) => {
  const [localValue, setLocalValue] = useState(value || defaultValue || '');
  const [errorMsg, setErrorMsg] = useState('');
  const getEditView = () => {
    if (editView) {
      return editView;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,react/display-name
    return ({ errorMessage, ...fProps }) => {
      return (
        <TextField
          {...fProps}
          isInvalid={`${errorMsg || ''}`.trim() !== ''}
          errorMsg={`${errorMsg || ''}`.trim()}
          {...editViewProps}
          value={localValue}
          testId={`edit-view-${testId || ''}`}
          placeholder={placeHolder}
          onChange={(e) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            setLocalValue(e.target.value || '');
          }}
          autoFocus
        />
      );
    };
  };

  const getReadView = () => {
    if (readView) {
      return readView;
    }

    // eslint-disable-next-line react/display-name
    return () => (
      <ViewWrapper
        data-testid={`read-view-${testId || ''}`}
        className={`view-wrapper ${`${value || ''}`.trim() === '' ? 'default-view' : ''} ${isDisabled === true ? 'disabled-view ' : ''}`}
      >
        {value || placeHolder || defaultValue || 'Please input some value...'}
      </ViewWrapper>
    );
  };

  const getIsEditing = () => {
    const errMsg = `${errorMsg || ''}`.trim();
    if (errMsg !== '') {
      return true;
    }

    if (isEditing !== undefined) {
      return isEditing;
    }

    return isDisabled ? false : isEditing;
  };

  return (
    <Origin
      {...props}
      testId={testId}
      onConfirm={() => {
        const errMsg = `${validate ? validate(localValue) : ''}`.trim();
        if (errMsg === '') {
          setErrorMsg('');
          onConfirm(localValue);
          return;
        }
        Toaster.showToast(errMsg, TOAST_TYPE_ERROR);
        setErrorMsg(errMsg);
      }}
      onCancel={() => {
        setLocalValue(value || defaultValue || '');
        setErrorMsg('');
        onCancel && onCancel();
      }}
      isEditing={getIsEditing()}
      readView={getReadView()}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      editView={getEditView()}
    />
  );
};

export default InlineEdit;
