import React from 'react';
import PopupBtn from '../common/PopupBtn';
import {
  DELETE,
  DELETE_INVALID_TEXT,
  NOT_ALLOW_DELETE,
} from './Warehouse.constants';
import { getFooterWithBtns } from '../common/PopupModal';
import SectionMessage from '../frameWork/SectionMessage';

const DeleteBtnNotAllowed = () => {
  return (
    <PopupBtn
      btnTxt={DELETE}
      title={NOT_ALLOW_DELETE}
      titleId={'WarehouseNotDeleteBtn'}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: NOT_ALLOW_DELETE,
        body: (
          <SectionMessage appearance="warning" allowClose={false}>
            {DELETE_INVALID_TEXT}
          </SectionMessage>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            appearance: 'primary',
            btnText: 'Cancel',
            onClick: () => setShowingModal(false),
          },
        }),
      })}
    />
  );
};

export default DeleteBtnNotAllowed;
