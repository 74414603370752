import ServiceHelper from '../helper/ServiceHelper';
import iAttributeSet from '../../types/attribute/iAttributeSet';

const endPoint = '/attributeSet';
const AttributeSetService = {
  getAll: ServiceHelper.getPaginatedAll<iAttributeSet>(endPoint),
  get: ServiceHelper.getById<iAttributeSet>(endPoint),
  create: ServiceHelper.create<iAttributeSet>(endPoint),
  update: ServiceHelper.updateById<iAttributeSet>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAttributeSet>(endPoint),
};

export default AttributeSetService;
