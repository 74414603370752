import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildPackBuildService from '../../services/build/BuildPackBuildService';
import Spinner from '../frameWork/Spinner';
import { iCellParams } from '../../helpers/DynamicTableHelper';
import iBuildPackBuild from '../../types/build/iBuildPackBuild';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import ComponentWrapper from '../frameWork/ComponentWrapper';
import Flex from '../frameWork/Flex';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import EntityNames from '../../helpers/EntityNames';
import BuildPackSelector from './BuildPackSelector';
import { iOptionWithData } from '../frameWork/Select';
import iBuildPack from '../../types/build/iBuildPack';

type iPacksForABuild = iComponentWithPageHeader & {
  buildId: string;
  testId?: string;
  className?: string;

  allowDelete?: boolean;
  allowCreate?: boolean;
};
const PacksForABuild = ({
  buildId,
  testId,
  className,
  headerProps,
  allowDelete,
  allowCreate,
  ...props
}: iPacksForABuild) => {
  const componentName = 'PacksForABuild';
  const testIdStr = `${testId || ''}-${componentName}`;
  const {
    renderDataTable,
    state,
    viewingState,
    renderDeleteBtn,
    renderEntityEditPopBtn,
  } = useListCrudHook({
    perPage: 9999999,
    getFn: (params) =>
      BuildPackBuildService.getAll({
        where: JSON.stringify({
          isActive: true,
          buildId: buildId,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Build,BuildPack',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getCreateBtn = () => {
    return renderEntityEditPopBtn<iBuildPackBuild>({
      testId: `${testIdStr}-create-btn`,
      entityName: EntityNames.BuildPack,
      createFn: (data) =>
        BuildPackBuildService.create({
          ...data,
          buildId: buildId,
        }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      updateFn: async () => null,
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'packId',
          label: 'Pack',
          isDisabled,
          isRequired: true,
          value: entity?.packId || '',
          testId: 'packId',
          renderComponent: (fProps, useAsForm, errorProps) => {
            const { fieldName, ...props } = fProps;
            return (
              <BuildPackSelector
                {...props}
                {...errorProps}
                onChange={(selected: iOptionWithData<iBuildPack>) => {
                  useAsForm.onFieldChange(fieldName, selected.data.id);
                }}
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = () => {
    return [
      {
        key: 'name',
        header: 'Name',
        isDefault: true,
        isSelectable: false,
        isSortable: true,
        cell: ({ data }: iCellParams<iBuildPackBuild>) =>
          `${data.BuildPack?.name || ''}`,
      },
      {
        key: 'description',
        header: 'Description',
        cell: ({ data }: iCellParams<iBuildPackBuild>) =>
          `${data.BuildPack?.description || ''}`,
      },
      ...(allowDelete !== true
        ? []
        : [
            {
              key: 'btns',
              header: '',
              cell: ({ data }: iCellParams<iBuildPackBuild>) => {
                return (
                  <Flex className={'justify-content-end'}>
                    {renderDeleteBtn({
                      deletingModel: data,
                      getDisplayName: () => data.BuildPack?.name || '',
                      deleteFnc: () =>
                        BuildPackBuildService.deactivate(data.id),
                    })}
                  </Flex>
                );
              },
            },
          ]),
    ];
  };

  const getContent = () => {
    if (state.isLoading && viewingState.currentPage === 1) {
      return <Spinner />;
    }

    return renderDataTable({
      columns: getColumns(),
      tblProps: {
        testId: `${testIdStr}-table`,
      },
    });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children:
          allowCreate !== true ? (
            headerProps?.children
          ) : (
            <PageTitleWithCreateBtn
              createBtn={getCreateBtn()}
              title={headerProps?.children}
            />
          ),
      }}
      {...props}
    >
      <ComponentWrapper componentName={componentName} className={className}>
        {getContent()}
      </ComponentWrapper>
    </ComponentWithPageHeader>
  );
};

export default PacksForABuild;
