import ComponentWrapper from '../../frameWork/ComponentWrapper';
import { useEffect, useState } from 'react';
import EntityNames from '../../../helpers/EntityNames';
import BuildService from '../../../services/build/BuildService';
import BuildStyleService from '../../../services/build/BuildStyleService';
import Toaster from '../../common/Toaster';
import iBuild from '../../../types/build/iBuild';
import iBuildStyle from '../../../types/build/iBuildStyle';

type iSizeEntity = iBuild | iBuildStyle;
type iAttributeBuildSizeDiv = {
  entityId: string;
  entityName: EntityNames.Build | EntityNames.BuildStyle;
  fieldName: string;
  placeHolder?: string;
  className?: string;
  testId?: string;
  isRequired?: boolean;
};
const AttributeBuildSizeDiv = ({
  entityId,
  entityName,
  fieldName,
  placeHolder,
  className,
  testId,
  isRequired,
}: iAttributeBuildSizeDiv) => {
  const [isLoading, setIsLoading] = useState(false);
  const [entity, setEntity] = useState<iSizeEntity | null>(null);

  useEffect(() => {
    const entityIdStr = `${entityId || ''}`.trim();
    if (entityIdStr === '') {
      setEntity(null);
      return;
    }
    const getData = () =>
      entityName === EntityNames.Build
        ? BuildService.get(entityIdStr)
        : BuildStyleService.get(entityIdStr);
    let isCancelled = false;
    setIsLoading(true);
    getData()
      .then((resp) => {
        if (isCancelled) {
          return;
        }
        setEntity(resp);
      })
      .catch((err) => {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [entityId, entityName]);

  const getSize = () => {
    const fieldNameStr = `${fieldName || ''}`.trim();
    if (fieldNameStr === '') {
      return '';
    }
    const entityObj = entity || {};
    if (!(fieldNameStr in entityObj)) {
      return '';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return entityObj[fieldName] || '';
  };

  const getContent = () => {
    const size = getSize();
    if (size === '') {
      return placeHolder;
    }
    return size;
  };

  const getClassName = () => {
    const size = getSize();
    const classNames = [className || ''];
    if (size === '') {
      classNames.push('default-view');
    }
    if (size === '' && isRequired) {
      classNames.push('is-invalid');
    }
    return classNames.join(' ').trim();
  };

  return (
    <ComponentWrapper
      componentName={'AttributeBuildSizeDiv'}
      isLoading={isLoading}
      className={getClassName()}
      testId={testId}
    >
      {getContent()}
    </ComponentWrapper>
  );
};

export default AttributeBuildSizeDiv;
