import ServiceHelper from '../helper/ServiceHelper';
import iFacility from '../../types/warehouse/iFacility';

const endPoint = '/facility';
const FacilityService = {
  getAll: ServiceHelper.getPaginatedAll<iFacility>(endPoint),
  get: ServiceHelper.getById<iFacility>(endPoint),
  create: ServiceHelper.create<iFacility>(endPoint),
  update: ServiceHelper.updateById<iFacility>(endPoint),
  deactivate: ServiceHelper.deactivateById<iFacility>(endPoint),
};

export default FacilityService;
