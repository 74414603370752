/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { CREATE_EDIT_FACILITY, iWarehouseUI } from './Warehouse.constants';
import iFacility from '../../types/warehouse/iFacility';
import FacilityService from '../../services/warehouse/FacilityService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import AddressSelector from '../address/AddressSelector';
import { iOptionWithData } from '../frameWork/Select';
import iAddress from '../../types/system/iAddress';
import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';
import WarehouseLocationSelector from './WarehouseLocationSelector';
import EntityEditPopupBtn from '../form/EntityEditPopupBtn';
import { getDefaultBtn } from '../common/PopupModal';
import { iSetShowingModalFn } from '../common/PopupBtn';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-right: auto;
`;
const initialState: iState = { isLoading: false };
type iState = {
  isLoading: boolean;
  facility?: iFacility;
};
const FacilityBtn = ({
  item,
  updateWarehouse,
  reloadWarehouse,
}: {
  item: iWarehouseUI;
  updateWarehouse: any;
  reloadWarehouse: any;
}) => {
  const { isFacility, facilityId } = item;
  const [state, setState] = useState<iState>(initialState);
  useEffect(() => {
    let isCancelled = false;
    const loadFacility = async () => {
      if (!isFacility || !facilityId) return;
      try {
        setState({
          ...state,
          isLoading: true,
        });
        const data = await FacilityService.get(facilityId, {
          where: JSON.stringify({
            isActive: true,
          }),
          include:
            'DefaultInwardsLocation,DefaultOutwardsLocation,LostAndFoundLocation,Address',
        });
        if (isCancelled) return;
        setState({
          ...state,
          isLoading: false,
          facility: data,
        });
      } catch (error) {
        Toaster.showApiError(error);
        setState({
          ...state,
          isLoading: false,
        });
      }
    };
    loadFacility();
    return () => {
      isCancelled = true;
    };
  }, [isFacility, facilityId]);

  const onRemove = async (
    setShowingModal: iSetShowingModalFn,
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setIsSaving(true);
    try {
      await FacilityService.deactivate(state.facility?.id || '');
      await updateWarehouse(item?.id || '', {
        facilityId: null,
        isFacility: false,
      });
      await reloadWarehouse(item?.id || '');
      setIsSaving(false);
      setShowingModal(false);
      Toaster.showToast('Facility removed', TOAST_TYPE_SUCCESS);
    } catch (error) {
      setIsSaving(false);
      Toaster.showApiError(error);
    }
  };

  return (
    <EntityEditPopupBtn<iFacility>
      testId={'facility-cud-popup-btn'}
      isLoading={state.isLoading}
      entityName={'Facility'}
      entity={state.facility}
      createFn={async (data) => {
        const facility = await FacilityService.create({
          ...data,
          warehouseLocationId: item?.id,
          name: item?.name,
        });
        await updateWarehouse(item?.id || '', {
          facilityId: facility.id,
          isFacility: true,
        });
        await reloadWarehouse(item?.id || '');
        return facility;
      }}
      updateFn={async (id, data) => await FacilityService.update(id, data)}
      btnTxt={CREATE_EDIT_FACILITY}
      getFormFields={({ entity, isDisabled }) => [
        {
          fieldName: 'defaultInwardsLocationId',
          label: 'Inwards Location',
          isDisabled,
          isRequired: true,
          value: entity?.defaultInwardsLocationId || '',
          testId: 'Facility-defaultInwardsLocationId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <WarehouseLocationSelector
                {...props}
                {...errorProps}
                onChange={(
                  option: iOptionWithData<iWarehouseLocation> | null,
                ) => {
                  useAsForm.onFieldChange(
                    'defaultInwardsLocationId',
                    option?.value || '',
                  );
                }}
              />
            );
          },
        },
        {
          fieldName: 'defaultOutwardsLocationId',
          label: 'Outwards Location',
          isDisabled,
          isRequired: true,
          value: entity?.defaultOutwardsLocationId || '',
          testId: 'Facility-defaultOutwardsLocationId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <WarehouseLocationSelector
                {...props}
                {...errorProps}
                onChange={(
                  option: iOptionWithData<iWarehouseLocation> | null,
                ) => {
                  useAsForm.onFieldChange(
                    'defaultOutwardsLocationId',
                    option?.value || '',
                  );
                }}
              />
            );
          },
        },
        {
          fieldName: 'lostAndFoundLocationId',
          label: 'Lost and Found Location',
          isDisabled,
          isRequired: true,
          value: entity?.lostAndFoundLocationId || '',
          testId: 'Facility-lostAndFoundLocationId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <WarehouseLocationSelector
                {...props}
                {...errorProps}
                onChange={(
                  option: iOptionWithData<iWarehouseLocation> | null,
                ) => {
                  useAsForm.onFieldChange(
                    'lostAndFoundLocationId',
                    option?.value || '',
                  );
                }}
              />
            );
          },
        },
        {
          fieldName: 'addressId',
          label: 'Address',
          isDisabled,
          isRequired: true,
          value: entity?.addressId || '',
          testId: 'Facility-addressId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <AddressSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iAddress> | null) => {
                  useAsForm.onFieldChange('addressId', option?.value || '');
                }}
              />
            );
          },
        },
      ]}
      preElement={(setShowingModal, setIsSaving) =>
        state.facility ? (
          <Wrapper>
            {getDefaultBtn({
              appearance: 'danger',
              btnText: 'Remove',
              onClick: async () => await onRemove(setShowingModal, setIsSaving),
              testId: 'facility-remove-btn',
            })}
          </Wrapper>
        ) : undefined
      }
    />
  );
};

export default FacilityBtn;
