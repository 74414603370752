import React from 'react';
import ComponentWithPageHeader from '../common/ComponentWithPageHeader';
import Spinner from '../frameWork/Spinner';

const SpinWhenLoading = () => {
  return (
    <ComponentWithPageHeader>
      <Spinner />
    </ComponentWithPageHeader>
  );
};

export default SpinWhenLoading;
