import iAttributeValue from '../../../types/attribute/iAttributeValue';
import iAttribute, {
  AttributeForSections,
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import styled from 'styled-components';
import TextField from '../../frameWork/TextField';
import Select, {
  convertSameValueToOption,
  iOption,
} from '../../frameWork/Select';
import { getErrorProps, iErrorMap } from '../../form/FormError';
import Toggle from '../../frameWork/Toggle';
import ProductSelector from '../../product/ProductSelector';
import ProductDiv from '../../product/ProductDiv';
import ContactCompanySelector from '../../contact/ContactCompanySelector';
import { ContactCompanyTypes } from '../../../types/contact/iContactCompany';
import AttributeSettingsHelper from './AttributeSettingsHelper';
import Flex from '../../frameWork/Flex';
import * as _ from 'lodash';
import TextArea from '../../frameWork/TextArea';
import { DatePicker } from '../../frameWork/DateTimePicker';
import BuildAreaService from '../../../services/build/BuildAreaService';
import BuildAreaSelector from '../../buildArea/BuildAreaSelector';
import HouseAreaSelector from '../../houseArea/HouseAreaSelector';
import EntityNames from '../../../helpers/EntityNames';
import AttachmentUploadPopupBtn from '../../asset/AttachmentUploadPopupBtn';
import { AssetTypes } from '../../../types/asset/iAsset';
import FormField from '../../frameWork/FormField';
import Button, { IconButton } from '../../frameWork/Button';
import Icons from '../../frameWork/Icons';
import AttachmentDiv from '../../asset/AttachmentDiv';
import DeleteConfirmPopupBtn from '../../common/DeleteConfirmPopupBtn';
import AttributeBuildSizeInput from './AttributeBuildSizeInput';

export type iAttributeValueInput = {
  entityId?: string;
  entityName?: EntityNames;
  attribute: iAttribute;
  className?: string;
  attributeValue?: iAttributeValue;
  isDisabled?: boolean;
  onChange: (newValue: string | string[] | null, errors: iErrorMap) => void;
  errorMap?: iErrorMap;
  appearance?: 'subtle' | 'default';
  showLabel?: boolean;
  isMulti?: boolean;
  valueChoices?: string[] | null;
  attributeSection: AttributeForSections | null; // null means ignore the section
};
const Wrapper = styled.div``;

const AttributeValueInput = ({
  attribute,
  attributeValue,
  isMulti = false,
  onChange,
  className = '',
  isDisabled = false,
  showLabel = true,
  errorMap = {},
  appearance = 'subtle',
  attributeSection,
  valueChoices = null,
  entityId,
  entityName,
}: iAttributeValueInput) => {
  const getInputErrorProps = (error: iErrorMap) => {
    return getErrorProps({
      fieldName: attribute.id,
      error: error,
    });
  };

  const getEntitySelectorChanged = (
    selected: iOption | iOption[] | null,
    errors: iErrorMap,
    formatSelectedValue?: (selected: iOption) => string,
  ) => {
    if (!selected) {
      onChange(null, errors);
      return;
    }
    if (Array.isArray(selected) === true) {
      onChange(
        selected.map((sel: iOption) =>
          formatSelectedValue ? formatSelectedValue(sel) : `${sel.data.id}`,
        ),
        errors,
      );
      return;
    }

    onChange(
      formatSelectedValue
        ? formatSelectedValue(selected as iOption)
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          `${selected.data.id}`,
      errors,
    );
  };

  const getSelectorOptionDisabled = (option: iOption) => {
    const options = valueChoices || [];
    if (options.length <= 0) {
      return false;
    }
    return options.indexOf(`${option.value || ''}`) < 0;
  };

  const getComponent = () => {
    const label = showLabel === true ? attribute.name : undefined;
    const selectedValue = attributeValue?.value || '';
    const isRequired = attributeSection
      ? AttributeSettingsHelper.isAttributeRequiredForSection(
          attribute,
          attributeSection,
        )
      : false;
    const isEditable = attributeSection
      ? AttributeSettingsHelper.isAttributeEditableForSection(
          attribute,
          attributeSection,
        )
      : true;
    const errors: iErrorMap = errorMap;

    switch (attribute.type) {
      case AttributeTypes.SUPPLIER_DROPDOWN: {
        return (
          <ContactCompanySelector
            appearance={appearance}
            isMulti={isMulti}
            contactCompanyType={ContactCompanyTypes.SUPPLIER}
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            isClearable={!isRequired}
            isOptionDisabled={getSelectorOptionDisabled}
            {...getInputErrorProps(errors)}
            value={selectedValue}
            onChange={(selected: iOption | iOption[] | null) =>
              getEntitySelectorChanged(selected, errors)
            }
          />
        );
      }
      case AttributeTypes.PRODUCT_DROPDOWN: {
        const { productTypeIds, showSelected, showSelectedPic } =
          AttributeSettingsHelper.getProductSelectorOptions(attribute);
        return (
          <ProductSelector
            isForBuildOnly={true}
            appearance={appearance}
            productTypeIds={productTypeIds}
            label={label}
            isMulti={isMulti}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            isOptionDisabled={getSelectorOptionDisabled}
            {...getInputErrorProps(errors)}
            value={selectedValue}
            onChange={(selected: iOption | iOption[] | null) =>
              getEntitySelectorChanged(selected, errors)
            }
            helperMsg={
              showSelected !== true || !selectedValue ? null : Array.isArray(
                  selectedValue,
                ) ? (
                <Flex className={'gap-025'}>
                  {_.uniq(selectedValue).map((selectedVal) => {
                    return (
                      <ProductDiv
                        key={selectedVal}
                        productId={selectedVal}
                        showPicture={showSelectedPic === true}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <ProductDiv
                  productId={selectedValue}
                  showPicture={showSelectedPic === true}
                />
              )
            }
          />
        );
      }
      case AttributeTypes.HOUSE_AREA_DROPDOWN: {
        if (!entityName || !entityId) {
          return (
            <HouseAreaSelector
              appearance={appearance}
              isMulti={isMulti}
              label={label}
              isRequired={isRequired}
              isDisabled={isDisabled || !isEditable}
              value={selectedValue}
              {...getInputErrorProps(errors)}
              onChange={(selected: iOption | iOption[] | null) =>
                getEntitySelectorChanged(selected, errors)
              }
            />
          );
        }
        return (
          <BuildAreaSelector
            appearance={appearance}
            isMulti={isMulti}
            label={label}
            isRequired={isRequired}
            entityId={entityId}
            entityName={entityName}
            isDisabled={isDisabled || !isEditable}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(selected: iOption | iOption[] | null) =>
              getEntitySelectorChanged(selected, errors, (sel) => {
                return `${sel?.data.HouseArea?.id || ''}`;
              })
            }
            getValuesFn={async (values: string[]) => {
              if (values.length <= 0) {
                return null;
              }
              return BuildAreaService.getAll({
                where: JSON.stringify({
                  houseAreaId: values,
                  entityName,
                  entityId,
                }),
                perPage: values.length,
                include: 'HouseArea',
              });
            }}
          />
        );
      }
      case AttributeTypes.CHECKBOX: {
        return (
          <Toggle
            separatedLines
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            {...getInputErrorProps(errors)}
            isChecked={selectedValue === 'Y'}
            onChange={(event) => {
              onChange(event.target.checked ? `Y` : 'N', errors);
            }}
          />
        );
      }
      case AttributeTypes.DROPDOWN: {
        return (
          <Select
            placeholder={'select ...'}
            label={label}
            isMulti={isMulti}
            isDisabled={isDisabled || !isEditable}
            isClearable
            appearance={Object.keys(errors).length > 0 ? 'default' : appearance}
            isRequired={isRequired}
            {...getInputErrorProps(errors)}
            value={
              selectedValue
                ? Array.isArray(selectedValue)
                  ? selectedValue.map((selectedVal) =>
                      convertSameValueToOption(selectedVal),
                    )
                  : convertSameValueToOption(selectedValue)
                : null
            }
            options={AttributeSettingsHelper.getDropdownOptions(attribute).map(
              (opt: string) => convertSameValueToOption(opt),
            )}
            isOptionDisabled={getSelectorOptionDisabled}
            onChange={(selected) => {
              if (
                !selected ||
                (Array.isArray(selected) && selected.length <= 0)
              ) {
                onChange(null, errors);
                return;
              }
              if (Array.isArray(selected)) {
                onChange(
                  selected.map((selected: iOption) => `${selected.value}`),
                  errors,
                );
                return;
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onChange(`${selected.value}`, errors);
            }}
          />
        );
      }
      case AttributeTypes.INPUT_NUMBER: {
        return (
          <TextField
            placeholder={'type in here...'}
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(event) => {
              onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                event.target.value || '',
                errors,
              );
            }}
          />
        );
      }
      case AttributeTypes.INPUT_TEXTAREA: {
        return (
          <TextArea
            placeholder={'type in here...'}
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(event) => {
              onChange(event.target.value || '', errors);
            }}
          />
        );
      }
      case AttributeTypes.INPUT_TEXT: {
        return (
          <TextField
            placeholder={'type in here...'}
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(event) => {
              onChange(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                event.target.value || '',
                errors,
              );
            }}
          />
        );
      }
      case AttributeTypes.INPUT_DATE_PICKER: {
        return (
          <DatePicker
            placeholder={'date ...'}
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            value={selectedValue}
            {...getInputErrorProps(errors)}
            onChange={(value: string) => onChange(value, errors)}
          />
        );
      }
      case AttributeTypes.FILE_UPLOADER: {
        return (
          <FormField
            label={label}
            isDisabled={isDisabled || !isEditable}
            isRequired={isRequired}
            {...getInputErrorProps(errors)}
            render={() => (
              <AttachmentUploadPopupBtn
                entityName={(entityName || '') as EntityNames}
                entityId={entityId || ''}
                isDisabled={isDisabled || !isEditable}
                assetTypes={[AssetTypes.ATTRIBUTE_VALUES]}
                onUploaded={(attachments) =>
                  onChange(attachments[0].id, errors)
                }
                renderBtn={(onClick) => {
                  return (
                    <Flex className={'align-items-center gap-1'}>
                      {selectedValue === '' ? (
                        <Button onClick={onClick} iconBefore={Icons.UploadIcon}>
                          Upload
                        </Button>
                      ) : null}
                      <AttachmentDiv
                        id={selectedValue}
                        elementAfter={
                          isDisabled || !isEditable ? null : (
                            <DeleteConfirmPopupBtn
                              titleId={selectedValue}
                              deleteFnc={async () => onChange(null, errors)}
                              renderBtn={(onClk) => {
                                return (
                                  <IconButton
                                    icon={Icons.TrashIcon}
                                    label={''}
                                    testId={'delete-btn'}
                                    appearance={'subtle'}
                                    spacing={'compact'}
                                    onClick={onClk}
                                  />
                                );
                              }}
                            />
                          )
                        }
                      />
                    </Flex>
                  );
                }}
              />
            )}
          />
        );
      }
      case AttributeTypes.BUIlD_SIZE_CHANGER: {
        const entityIdStr = `${entityId || ''}`.trim();
        const entityNameStr = `${entityName || ''}`.trim() as EntityNames;
        const fieldName =
          AttributeSettingsHelper.getBuildSizeFieldName(attribute).trim();
        if (
          entityIdStr === '' ||
          fieldName === '' ||
          [EntityNames.Build, EntityNames.BuildStyle].indexOf(entityNameStr) < 0
        ) {
          return null;
        }

        return (
          <AttributeBuildSizeInput
            entityId={entityIdStr}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            entityName={entityNameStr}
            label={label}
            isRequired={isRequired}
            fieldName={fieldName}
            {...getInputErrorProps(errors)}
            isDisabled={isDisabled || !isEditable}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Wrapper
      className={`AttributeValueEditor ${className}`}
      data-testid={'attr-val-editor'}
    >
      {getComponent()}
    </Wrapper>
  );
};

export default AttributeValueInput;
