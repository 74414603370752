import SettingsPage from '../../layouts/SettingsPage';
import DeveloperGuideCategoryList from '../../components/developerGuideCategory/DeveloperGuideCategoryList';

const DeveloperGuideCategoryPage = () => {
  return (
    <SettingsPage title={'Developer Guide Category'}>
      {(headerProps) => (
        <DeveloperGuideCategoryList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default DeveloperGuideCategoryPage;
