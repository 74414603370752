import ProtectedPage from '../../layouts/ProtectedPage';
import WarehouseLocationList from '../../components/warehouse/WarehouseLocationList';

const WarehousesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Warehouses`,
      }}
    >
      {(headerProps) => <WarehouseLocationList headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default WarehousesPage;
