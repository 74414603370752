import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { getErrorProps, iErrorMap } from '../form/FormError';
import TextField from '../frameWork/TextField';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import Toaster from '../common/Toaster';
import Toggle from '../frameWork/Toggle';
import AttributeSetCodeSelector from './AttributeSetCodeSelector';
import AttributeSelector from '../attribute/AttributeSelector';
import SectionMessage from '../frameWork/SectionMessage';
import { iOptionWithData } from '../frameWork/Select';
import iAttribute from '../../types/attribute/iAttribute';
import AttributeSetSettingsHelper, {
  AttrSetSettingFieldNames,
  iAttrSetDisplayGroup,
} from './AttributeSetSettingsHelper';
import AttributeSettingsDisplayGroupTbl from './AttributeSettingsDisplayGroupTbl';
import Heading from '../frameWork/Heading';

export type iAttributeSetEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  attributeSet?: iAttributeSet;
  isDisabled?: boolean;
  onSaved?: (saved: iAttributeSet, iCreated: boolean) => void;
  allowUpdatedCode?: boolean;
};
const AttributeSetEditPopupBtn = ({
  attributeSet,
  isDisabled = false,
  onSaved,
  onClose,
  allowUpdatedCode = false,
  ...props
}: iAttributeSetEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const attributeSetId = `${attributeSet?.id || ''}`.trim();

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
    onClose && onClose(setModelShowing);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const checkingData = {
      ...attributeSet,
      ...editingData,
    };
    if (`${checkingData.code || ''}`.trim() === '') {
      errors.code = ['Code is required'];
    }
    if (`${checkingData.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }

    const saveFn = () =>
      attributeSetId === ''
        ? AttributeSetService.create(editingData)
        : AttributeSetService.update(attributeSetId, editingData);
    setIsSaving(true);
    saveFn()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, attributeSetId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const handleChanged = (fieldName: string, newValue: string | null) => {
    setEditingData({
      ...editingData,
      [fieldName]: newValue,
    });
  };

  const getCurrentAttributeSet = () => {
    return {
      ...(attributeSet || {}),
      ...(editingData || {}),
      settings: {
        ...(attributeSet?.settings || {}),
        ...(editingData?.settings || {}),
      },
    } as iAttributeSet;
  };

  const getCurrentSettings = () => {
    return getCurrentAttributeSet().settings;
  };

  const handleSettingsChanged = (newValue: iConfigParams) => {
    handleChanged('settings', {
      ...getCurrentSettings(),
      ...newValue,
    });
  };

  const getDisplayGroupOptions = () => {
    const displayGroupSettings =
      AttributeSetSettingsHelper.getDisplayGroup(getCurrentAttributeSet()) ||
      {};
    const attributeSetCode = `${attributeSet?.code || ''}`.trim();
    if (
      Object.keys(displayGroupSettings).length <= 0 ||
      attributeSetCode === ''
    ) {
      return null;
    }

    return (
      <>
        <SectionMessage>
          Attributes that are NOT provided below in the Display Groups will be
          hidden.
        </SectionMessage>
        <AttributeSelector
          label={'Section Title'}
          value={displayGroupSettings.sectionId}
          attributeSetCodes={[attributeSetCode]}
          onChange={(selected: iOptionWithData<iAttribute>) => {
            const selectedId = `${selected.data.id || ''}`.trim();
            handleSettingsChanged(
              AttributeSetSettingsHelper.formDisplayGroup(
                getCurrentAttributeSet(),
                {
                  ...displayGroupSettings,
                  sectionId: selectedId,
                },
              ),
            );
          }}
        />
        <AttributeSettingsDisplayGroupTbl
          attributeSet={getCurrentAttributeSet()}
          headerProps={{
            children: <Heading size={'xsmall'}>Display Groups</Heading>,
            className: 'no-bottom-margin',
          }}
          onChanged={(newGroups: iAttrSetDisplayGroup[]) => {
            handleSettingsChanged(
              AttributeSetSettingsHelper.formDisplayGroup(
                getCurrentAttributeSet(),
                {
                  ...displayGroupSettings,
                  groups: newGroups,
                },
              ),
            );
          }}
        />
      </>
    );
  };

  const getDisplayGroupDiv = () => {
    const settings = getCurrentSettings();
    if (settings.isMulti !== true) {
      return null;
    }

    const displayGroupSettings =
      AttributeSetSettingsHelper.getDisplayGroup(getCurrentAttributeSet()) ||
      {};
    return (
      <>
        <Toggle
          label={'Enable Display Group? '}
          testId={'is-multi'}
          isChecked={Object.keys(displayGroupSettings).length > 0}
          helperMsg={'If yes, system display as a table with provided levels.'}
          onChange={(event) => {
            handleSettingsChanged(
              event.target.checked === true
                ? AttributeSetSettingsHelper.formDisplayGroup(
                    getCurrentAttributeSet(),
                    {
                      ...displayGroupSettings,
                      sectionId: '',
                    },
                  )
                : { [AttrSetSettingFieldNames.displayGroup]: null },
            );
          }}
        />
        {getDisplayGroupOptions()}
      </>
    );
  };

  const getBody = () => {
    return (
      <>
        <AttributeSetCodeSelector
          value={editingData?.code || attributeSet?.code}
          testId={'code-selector'}
          isDisabled={
            isDisabled || (attributeSetId !== '' && allowUpdatedCode !== true)
          }
          isRequired
          {...getErrorProps({
            error: errorMap,
            fieldName: 'code',
          })}
          onChange={(selected) => {
            handleChanged('code', `${selected?.value || ''}`);
          }}
        />

        <TextField
          label={'Name'}
          placeholder={'Name'}
          isDisabled={isDisabled}
          testId={'name'}
          isRequired
          value={editingData.name || attributeSet?.name || ''}
          {...getErrorProps({
            error: errorMap,
            fieldName: 'name',
          })}
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            handleChanged('name', event.target.value || '');
          }}
        />

        <TextField
          label={'Description'}
          placeholder={'Description'}
          testId={'description'}
          isDisabled={isDisabled}
          value={editingData.description || attributeSet?.description || ''}
          {...getErrorProps({
            error: errorMap,
            fieldName: 'description',
          })}
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            handleChanged('description', event.target.value || '');
          }}
        />

        <Toggle
          label={'Has multiple?'}
          testId={'is-multi'}
          isChecked={getCurrentSettings().isMulti === true}
          helperMsg={'If yes, system display as a table to capture data.'}
          onChange={(event) => {
            handleSettingsChanged({
              isMulti: event.target.checked === true,
            });
          }}
        />
        {getDisplayGroupDiv()}
      </>
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeSetEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: (
          <>
            Editing Attribute Set <small>{attributeSet?.name}</small>
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: getBody(),
      })}
    />
  );
};
export default AttributeSetEditPopupBtn;
