import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../../common/PopupBtn';
import { getFooterWithBtns } from '../../common/PopupModal';
import Icons from '../../frameWork/Icons';
import { getErrorProps, iErrorMap } from '../../form/FormError';
import { useEffect, useRef, useState } from 'react';
import { iConfigParams } from '../../../services/AppService';
import { iAttributeItemWithValueMap } from '../../../types/attribute/iAttributeItem';
import Toaster from '../../common/Toaster';
import iAttributeItem from '../../../types/attribute/iAttributeItem';
import AttributeItemService from '../../../services/attribute/AttributeItemService';
import BuildAreaService from '../../../services/build/BuildAreaService';
import AttributeService from '../../../services/attribute/AttributeService';
import Spinner from '../../frameWork/Spinner';
import { iOption } from '../../frameWork/Select';
import TextField from '../../frameWork/TextField';
import StringHelper from '../../../helpers/StringHelper';
import iAttribute, {
  AttributeForSections,
} from '../../../types/attribute/iAttribute';
import AttributeValueInput from './AttributeValueInput';
import AttributeValueService from '../../../services/attribute/AttributeValueService';
import AttributeSettingsHelper from './AttributeSettingsHelper';
import iAttributeValue from '../../../types/attribute/iAttributeValue';
import AttributeInputHelper from './AttributeInputHelper';
import EntityNames from '../../../helpers/EntityNames';
import AttributeValueInputWrapper from './AttributeValueInputWrapper';
import BuildAreaSelector from '../../buildArea/BuildAreaSelector';

export type iAttributeItemEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  entityId: string;
  entityName: EntityNames;
  attributeSetCode: string;
  attributeItem?: iAttributeItemWithValueMap;
  isDisabled?: boolean;
  needQty?: boolean;
  needArea?: boolean;
  onSaved?: (saved: iAttributeItem, isCreated: boolean) => void;
  attributeSection: AttributeForSections;
};

type iValuesMap = { [key: string]: iAttributeValue | null };
const AttributeItemEditPopupBtn = ({
  attributeItem,
  attributeSetCode,
  isDisabled = false,
  needQty = false,
  needArea = false,
  onSaved,
  entityName,
  entityId,
  attributeSection,
  onClose,
  ...props
}: iAttributeItemEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [attributes, setAttributes] = useState<iAttribute[]>([]);
  const requiredAttributesRef = useRef<{ [key: string]: boolean }>({});
  const attrDefaultValueRef = useRef<{ [key: string]: { value: string } }>({});

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([
      AttributeService.getAll({
        where: JSON.stringify({
          isActive: true,
          attributeSetCode: attributeSetCode,
        }),
        sort: 'sort:ASC',
        perPage: 999999,
      }),
    ])
      .then((result) => {
        if (isCanceled) return;
        setAttributes(result[0].data || []);
      })
      .catch((err) => {
        if (isCanceled) return;
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [attributeSetCode]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    requiredAttributesRef.current = {};
    attrDefaultValueRef.current = {};
    setModelShowing(false);
    onClose && onClose(setModelShowing);
  };

  const handleRequiredAttributesUpdate = (
    attributeId: string,
    isRequired: boolean,
  ) => {
    const currentAttributes = requiredAttributesRef.current;

    if (currentAttributes[attributeId] !== isRequired) {
      // Update the value in the ref object without causing a re-render
      currentAttributes[attributeId] = isRequired;
    }
  };

  const handleDefaultAttributeValueUpdate = (
    attributeId: string,
    newValue: string,
  ) => {
    const attrDefaultValue = attrDefaultValueRef.current;

    if (attrDefaultValue[attributeId]?.value !== newValue) {
      // Update the value in the ref object without causing a re-render
      attrDefaultValue[attributeId] = { value: newValue };
    }
  };

  const handleChange = (fieldName: string, value: string | null) => {
    setEditingData({
      ...editingData,
      [fieldName]: value,
    });
  };

  const preSave = () => {
    let errors: iErrorMap = {};
    const data = { ...(attributeItem || {}), ...editingData };
    if (needQty === true) {
      if (`${data.qty || ''}`.trim() === '') {
        errors.qty = ['Qty is required'];
      }
      if (!StringHelper.isNumeric(`${data.qty || ''}`.trim())) {
        errors.qty = ['Qty needs to be a number'];
      }
    }
    if (needArea === true) {
      if (`${data.houseAreaId || ''}`.trim() === '') {
        errors.houseAreaId = ['Area is required'];
      }
    }
    const valuesMap: iValuesMap = {
      ...('valuesMap' in editingData ? editingData['valuesMap'] : {}),
      ...attrDefaultValueRef.current,
    };
    for (const attribute of attributes) {
      const requiredErrors = AttributeInputHelper.getAttributeValueErrors({
        attribute: AttributeInputHelper.enforceAnAttributeIsRequired(
          attribute,
          attributeSection,
          attribute.id in requiredAttributesRef.current &&
            requiredAttributesRef.current[attribute.id],
        ),
        attributeSection,
        attributeValue: {
          value:
            attribute.id in valuesMap
              ? `${valuesMap[attribute.id]?.value || ''}`.trim()
              : '',
        },
      });
      errors = {
        ...errors,
        ...requiredErrors,
      };
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const attributeItemId = `${attributeItem?.id || ''}`.trim();
    const { valuesMap, ...data } = editingData;
    const itemData = {
      ...data,
      attributeSetCode,
      entityId,
      entityName,
    };

    const saveAllValues = (itemId: string) => {
      const valuesMapWithDefaults = {
        ...(valuesMap || {}),
        ...attrDefaultValueRef.current,
      };
      const promises = Object.keys(valuesMapWithDefaults)
        .filter((attributeId) => {
          if (!(attributeId in valuesMapWithDefaults)) {
            return false;
          }
          const attValue = valuesMapWithDefaults[attributeId] || {};
          if (attValue.value === undefined) {
            return false;
          }
          return true;
        })
        .map((attributeId) => {
          const attValue = valuesMapWithDefaults[attributeId] || {};
          const attValueId = `${attValue.id || ''}`.trim();
          if (attValueId !== '') {
            return attValue.value === null
              ? AttributeValueService.deactivate(attValueId)
              : AttributeValueService.update(attValueId, {
                  value: attValue.value,
                });
          }
          return AttributeValueService.create({
            attributeId,
            attributeSetCode,
            itemId,
            value: attValue.value,
          });
        })
        .filter((promise) => promise !== null);
      return Promise.all(promises);
    };

    setIsSaving(true);
    const getFnc =
      attributeItemId === ''
        ? async () => {
            const item = await AttributeItemService.create(itemData);
            await saveAllValues(item.id);
            return item;
          }
        : async () => {
            const updatedItem = await AttributeItemService.update(
              attributeItemId,
              itemData,
            );
            await saveAllValues(attributeItemId);
            return updatedItem;
          };

    getFnc()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, attributeItemId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const getBody = () => {
    if (isLoading === true) {
      return <Spinner />;
    }
    return (
      <>
        {needArea && (
          <BuildAreaSelector
            label={'Area'}
            isRequired
            testId={'item-houseAreaId'}
            entityId={entityId}
            entityName={entityName}
            isDisabled={isSaving || isDisabled}
            value={editingData.houseAreaId || attributeItem?.houseAreaId}
            {...getErrorProps({ fieldName: 'houseAreaId', error: errorMap })}
            onChange={(selected: iOption) =>
              handleChange(
                'houseAreaId',
                selected.data?.houseAreaId
                  ? `${selected.data.houseAreaId || ''}`.trim()
                  : null,
              )
            }
            getValuesFn={async (values: string[]) => {
              if (values.length === 0) {
                return null;
              }
              return BuildAreaService.getAll({
                where: JSON.stringify({
                  houseAreaId: values,
                  entityName,
                  entityId,
                }),
                perPage: values.length,
                include: 'HouseArea',
              });
            }}
          />
        )}
        {needQty && (
          <TextField
            label={'Qty'}
            isRequired
            testId={'item-qty'}
            isDisabled={isSaving || isDisabled}
            placeholder={'Quantity'}
            value={editingData.qty || attributeItem?.qty || ''}
            {...getErrorProps({ fieldName: 'qty', error: errorMap })}
            onChange={(event) =>
              handleChange(
                'qty',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                event.target.value || '',
              )
            }
          />
        )}
        {AttributeSettingsHelper.filterReadableAttrsForSection(
          attributes,
          attributeSection,
        ).map((attribute) => {
          const valuesMap =
            editingData.valuesMap || attributeItem?.valuesMap || {};
          const currentAttributeItemAttrSetCode =
            `${attributeItem?.attributeSetCode || ''}`.trim();
          return (
            <AttributeValueInputWrapper
              entityId={entityId}
              entityName={entityName}
              attribute={attribute}
              key={attribute.id}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              attributeItem={{
                ...(attributeItem || {}),
                attributeSetCode:
                  currentAttributeItemAttrSetCode === ''
                    ? attributeSetCode
                    : currentAttributeItemAttrSetCode,
                valuesMap,
              }}
            >
              {({
                isRequiredByAttrCondition,
                valueByAttrCondition,
                limitedOptionValues,
              }) => {
                const attValue =
                  attribute.id in valuesMap ? valuesMap[attribute.id] : null;
                handleRequiredAttributesUpdate(
                  attribute.id,
                  isRequiredByAttrCondition,
                );
                let valueByDefaultCond = {};
                if (
                  `${attValue?.id || ''}`.trim() === '' &&
                  valueByAttrCondition !== null
                ) {
                  valueByDefaultCond = { value: valueByAttrCondition };
                  handleDefaultAttributeValueUpdate(
                    attribute.id,
                    valueByAttrCondition,
                  );
                }
                return (
                  <AttributeValueInput
                    entityName={entityName}
                    entityId={entityId}
                    attributeSection={attributeSection}
                    valueChoices={limitedOptionValues || []}
                    attribute={AttributeInputHelper.enforceAnAttributeIsRequired(
                      attribute,
                      attributeSection,
                      isRequiredByAttrCondition,
                    )}
                    appearance={'default'}
                    isDisabled={isSaving || isDisabled}
                    attributeValue={{
                      ...(attValue || {}),
                      ...valueByDefaultCond,
                    }}
                    errorMap={errorMap}
                    onChange={(newValue) => {
                      handleChange('valuesMap', {
                        ...valuesMap,
                        [attribute.id]: {
                          ...(attValue || {}),
                          value: newValue,
                        },
                      });
                    }}
                  />
                );
              }}
            </AttributeValueInputWrapper>
          );
        })}
      </>
    );
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: (
          <>
            {`${attributeItem?.id || ''}`.trim() === ''
              ? 'Creating'
              : 'Updating'}{' '}
            a {attributeSetCode.replace('_', ' ')}
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving || isLoading,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving || isLoading,
            iconBefore: Icons.SendIcon,
            btnText:
              `${attributeItem?.id || ''}`.trim() === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: getBody(),
      })}
    />
  );
};

export default AttributeItemEditPopupBtn;
