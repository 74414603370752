import Select, { iSelect } from '../frameWork/Select';
import { AttributeSetCodes } from '../../types/attribute/iAttributeSet';
import { useEffect, useState } from 'react';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import { HEADER_NAME_SELECTING_FIELDS } from '../../services/AppService';
import Toaster from '../common/Toaster';
import Spinner from '../frameWork/Spinner';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type iAttributeSetCodeSelector = iSelect<any>;
const AttributeSetCodeSelector = ({
  value,
  ...props
}: iAttributeSetCodeSelector) => {
  const attributeSetCodes = Object.values(AttributeSetCodes);
  const [usedAttributeSetCodes, setUsedAttributeSetCodes] = useState<string[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    AttributeSetService.getAll(
      {
        where: JSON.stringify({
          isActive: true,
        }),
        currentPage: 1,
        perPage: 99999999,
      },
      {
        headers: {
          [HEADER_NAME_SELECTING_FIELDS]: JSON.stringify(['code']),
        },
      },
    )
      .then((resp) => {
        if (isCanceled) return;
        setUsedAttributeSetCodes(
          (resp.data || []).map((attributeSet) =>
            `${attributeSet.code || ''}`.trim().toLowerCase(),
          ),
        );
      })
      .catch((err) => {
        if (isCanceled) return;
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) return;
        setIsLoading(false);
      });

    return () => {
      isCanceled = true;
    };
  }, []);

  const getSelectedValue = () => {
    const selectedCode = `${value || ''}`.trim();
    if (selectedCode === '') {
      return undefined;
    }
    return {
      label: selectedCode,
      value: selectedCode,
    };
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Select
      label={'Code'}
      placeholder={'AttributeSet Code'}
      value={getSelectedValue()}
      options={attributeSetCodes
        .filter(
          (code) =>
            usedAttributeSetCodes.indexOf(
              `${code || ''}`.trim().toLowerCase(),
            ) < 0,
        )
        .map((code) => ({
          label: code,
          value: code,
        }))}
      {...props}
    />
  );
};

export default AttributeSetCodeSelector;
