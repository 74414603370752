import { iComponentWithPageHeader } from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React, { useState } from 'react';
import DynamicTableHelper from '../../helpers/DynamicTableHelper';
import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import SectionMessage from '../frameWork/SectionMessage';
import Flex from '../frameWork/Flex';
import AttributeSetEditPopupBtn from './AttributeSetEditPopupBtn';
import Icons from '../frameWork/Icons';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import styled from 'styled-components';
import AttributeList from '../attribute/AttributeList';
import AttributeEditPopupBtn from '../attribute/AttributeEditPopupBtn';
import MathHelper from '../../helpers/MathHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import { iSetShowingModalFn } from '../common/PopupBtn';

const Wrapper = styled.div``;
export type iAttributeSetDetailsPanel = iComponentWithPageHeader & {
  attributeSet: iAttributeSet;
  allowCreate?: boolean;
  allowDelete?: boolean;
  allowEdit?: boolean;
  testId?: string;
  className?: string;
  onAttributeSetUpdated?: (attributeSet: iAttributeSet) => void;
  onAttributeSetDeleted?: (attributeSet: iAttributeSet) => void;
  allowUpdatedCode?: boolean;
  allowDeleteAttributeSet?: boolean;
};
const AttributeSetDetailsPanel = ({
  attributeSet,
  headerProps,
  allowCreate,
  allowDelete,
  allowEdit,
  allowUpdatedCode,
  onAttributeSetUpdated,
  onAttributeSetDeleted,
  allowDeleteAttributeSet,
  className,
  testId,
  ...props
}: iAttributeSetDetailsPanel) => {
  const componentName = 'AttributeSet-details';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [forceReloadList, setForceReloadList] = useState(0);
  const getRankingMessage = () => {
    if (allowEdit !== true) {
      return undefined;
    }
    return (
      <SectionMessage title={'You can reorder the attributes below'}>
        You can reorder the attributes below by drag and drop each row.
      </SectionMessage>
    );
  };

  const handlePopupClose = (setModelShowing: iSetShowingModalFn) => {
    setShowEditPopup(false);
    setModelShowing(false);
  };

  const getFlags = (label: string, flagFieldName: string) => {
    const settings = attributeSet?.settings || {};
    const checked =
      flagFieldName in settings && settings[flagFieldName] === true;
    if (!checked) {
      return null;
    }
    return (
      <Flex className={'gap-050 align-items-center text-success'}>
        <div>{DynamicTableHelper.getCheckedIcon(checked)}</div>
        <div>{label}</div>
      </Flex>
    );
  };

  const getCreateBtn = () => {
    if (!allowCreate) {
      return null;
    }
    return (
      <AttributeEditPopupBtn
        attributeSet={attributeSet}
        onSaved={() => setForceReloadList(MathHelper.add(forceReloadList, 1))}
        renderBtn={(onClick) => {
          return getCreateIconBtn({ onClick });
        }}
      />
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <AttributeList
        {...props}
        pageSize={999999}
        attributeSetCode={attributeSet.code as AttributeSetCodes}
        allowDelete={allowDelete}
        allowEdit={allowEdit}
        forceReload={forceReloadList}
        isRankable
        selectiveColumnKey={SelectiveColKeys.ATTRIBUTE_SET_DETAILS_ATTR_LIST}
        headerProps={{
          ...headerProps,
          children:
            allowCreate === true ? (
              <PageTitleWithCreateBtn
                createBtn={getCreateBtn()}
                title={headerProps?.children || ''}
              />
            ) : (
              headerProps?.children
            ),
          bottomBar: getRankingMessage(),
          actions: (
            <Flex className={'gap-1 align-items-center'}>
              {getFlags('Multi', 'isMulti')}
              {allowEdit === true && (
                <>
                  <DropdownMenu
                    placement={'bottom-end'}
                    shouldRenderToParent
                    trigger={({ triggerRef, ...props }) => {
                      return (
                        <IconButton
                          {...props}
                          icon={Icons.MoreIcon}
                          ref={triggerRef}
                          label={''}
                          isSelected={false}
                        />
                      );
                    }}
                  >
                    <DropdownItemGroup>
                      <DropdownItem onClick={() => setShowEditPopup(true)}>
                        Edit {attributeSet.name || ''}
                      </DropdownItem>
                      {allowDeleteAttributeSet === true && (
                        <DropdownItem onClick={() => setShowDeletePopup(true)}>
                          Delete {attributeSet.name || ''}
                        </DropdownItem>
                      )}
                    </DropdownItemGroup>
                  </DropdownMenu>
                  <AttributeSetEditPopupBtn
                    forceShown={showEditPopup}
                    attributeSet={attributeSet}
                    onSaved={onAttributeSetUpdated}
                    allowUpdatedCode={allowUpdatedCode}
                    renderBtn={() => null}
                    onClose={handlePopupClose}
                  />
                  <DeleteConfirmPopupBtn
                    onClose={handlePopupClose}
                    onCancel={() => {
                      setShowDeletePopup(false);
                    }}
                    forceShown={showDeletePopup}
                    titleId={`delete-${attributeSet.id}`}
                    deleteFnc={() =>
                      AttributeSetService.deactivate(attributeSet.id)
                    }
                    renderBtn={() => null}
                    onDeleted={(deleted) =>
                      onAttributeSetDeleted && onAttributeSetDeleted(deleted)
                    }
                  />
                </>
              )}
            </Flex>
          ),
        }}
      />
    </Wrapper>
  );
};

export default AttributeSetDetailsPanel;
