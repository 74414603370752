import ServiceHelper from '../helper/ServiceHelper';
import iDeveloperGuideCategory from '../../types/developerGuideCategory/iDeveloperGuideCategory';

const endPoint = '/developerGuideCategory';
const DeveloperGuideCategoryService = {
  getAll: ServiceHelper.getPaginatedAll<iDeveloperGuideCategory>(endPoint),
  get: ServiceHelper.getById<iDeveloperGuideCategory>(endPoint),
  create: ServiceHelper.create<iDeveloperGuideCategory>(endPoint),
  update: ServiceHelper.updateById<iDeveloperGuideCategory>(endPoint),
  deactivate: ServiceHelper.deactivateById<iDeveloperGuideCategory>(endPoint),
};

export default DeveloperGuideCategoryService;
