enum EntityNames {
  Address = 'Address',
  User = 'User',
  Build = 'Build',
  BuildPack = 'BuildPack',
  BuildStyle = 'BuildStyle',
  BuildStylePrice = 'BuildStylePrice',
  Product = 'Product',
  OrderPageTemplate = 'OrderPageTemplate',
  BalRate = 'BalRate',
  Bank = 'Bank',
}

export default EntityNames;
