/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import ComponentWithPageHeader from '../common/ComponentWithPageHeader';
import iWarehouseLocation from '../../types/warehouse/iWarehouseLocation';
import WarehouseService from '../../services/warehouse/WarehouseService';
import Toaster from '../common/Toaster';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import TableTree, {
  Cell,
  Header,
  Headers,
  Row,
  Rows,
} from '@atlaskit/table-tree';
import Button from '../frameWork/Button';
import arrayToTree from 'array-to-tree';
import UtilsService from '../../services/UtilsService';
import Icons from '../frameWork/Icons';
import TextBadge from './TextBadge';
import MoreIconPopup from './MoreIconPopup';
import {
  ALLOW_PARTS,
  CREATE_SUB_LOCATION,
  EDIT_ALLOW_PART,
  iWarehouseUI,
  MOVE_WAREHOUSE,
  MOVE_WARNING_MESSAGE,
  PopUpContentContainer,
} from './Warehouse.constants';
import ComposeSecondaryText from './ComposeSecondaryText';
import { iPageHeader } from '../frameWork/PageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import SpinWhenLoading from './SpinWhenLoading';
import EntityEditPopupBtn from '../form/EntityEditPopupBtn';
import DeleteBtn from './DeleteBtn';
import DeleteBtnNotAllowed from './DeleteBtnNotAllowed';
import FacilityBtn from './FacilityBtn';
import WarehouseLocationSelector from './WarehouseLocationSelector';
import { iOptionWithData } from '../frameWork/Select';
import _ from 'lodash';
import SectionMessage from '../frameWork/SectionMessage';
import Toggle from '../frameWork/Toggle';
const WarehouseListWrapper = styled.div`
  div[role='row'] {
    align-items: center;
    min-height: 60px;
  }
`;

type iState = {
  items: Array<iWarehouseLocation>;
  isLoading: boolean;
  isConfirming: boolean;
};
const initialState: iState = {
  items: [],
  isLoading: true,
  isConfirming: false,
};

const WarehouseLocationList = ({
  id,
  headerProps,
}: {
  id?: string;
  headerProps?: iPageHeader;
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isCancelled = false;
    const loadTableData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await WarehouseService.getWarehousesByParentId();
        if (isCancelled) return;
        setState((preState) => ({
          ...preState,
          items: data,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        Toaster.showApiError(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    loadTableData();
    return () => {
      isCancelled = true;
    };
  }, [id]);

  const expandItem = async (parentItem: iWarehouseUI) => {
    // if (parentItem.children && parentItem.children.length > 0) return;
    setState({ ...state, isConfirming: true });
    try {
      const { data }: { data: iWarehouseLocation[] } =
        await WarehouseService.getWarehousesByParentId(parentItem.id);
      const diff = data.filter((d: iWarehouseLocation) => {
        if (!parentItem.children || parentItem.children.length === 0) {
          return true;
        }
        return !parentItem.children.find((p: iWarehouseUI) => p.id === d.id);
      });
      setState({
        ...state,
        items: [...state.items, ...diff],
        isConfirming: false,
      });
    } catch (error) {
      Toaster.showApiError(error);
      setState({ ...state, isConfirming: false });
    }
  };

  const updateLocalWarehouse = (newWarehouse: iWarehouseLocation) => {
    setState((preState) => ({
      ...preState,
      isLoading: false,
      items: preState.items.map((i: iWarehouseLocation) =>
        newWarehouse.id === i.id ? newWarehouse : i,
      ),
    }));
  };

  const createFn = async (postData: any) =>
    await WarehouseService.createWarehouse({
      ...postData,
    });
  const afterCreate = (saved: iWarehouseLocation) =>
    setState({
      ...state,
      items: [
        ...state.items.map((i: iWarehouseLocation) =>
          i.id === saved.parentId ? { ...i, hasChild: true } : i,
        ),
        saved,
      ],
    });

  const reloadWarehouse = async (reloadId: string) => {
    const reloaded = await WarehouseService.getWarehouseDetail(reloadId);
    setState({
      ...state,
      items: state.items.map((i: iWarehouseLocation) =>
        i.id === reloaded.id ? reloaded : i,
      ),
      isConfirming: false,
    });
  };

  const getDeleteBtn = (item: iWarehouseUI) => {
    if (
      item.hasChild ||
      (item.productsCount && item.productsCount !== 0) ||
      (item.children && item.children.length !== 0)
    ) {
      return <DeleteBtnNotAllowed />;
    }
    return (
      <DeleteBtn
        deletingModel={item}
        getDisplayName={(item) => item.locationCode}
        onDeleted={() =>
          setState({
            ...state,
            isConfirming: false,
            items: state.items.filter(
              (w: iWarehouseLocation) => w.id !== item.id,
            ),
          })
        }
      />
    );
  };

  const getCreateBtn = (item?: iWarehouseUI) => {
    return (
      <EntityEditPopupBtn<iWarehouseUI>
        entityName={item ? 'Sub Warehouse' : 'Warehouse'}
        entity={undefined}
        onSaved={(saved) => afterCreate(saved)}
        createFn={async (data) =>
          await createFn({ allowParts: false, ...data, parentId: item?.id })
        }
        updateFn={async (id, data) =>
          await createFn({ ...data, parentId: item?.id })
        }
        renderBtn={
          item
            ? (onClick) => (
                <Button onClick={onClick}>{CREATE_SUB_LOCATION}</Button>
              )
            : (onClick) =>
                getCreateIconBtn({
                  onClick: onClick,
                  label: `Create a new Warehouse`,
                  isTooltipDisabled: false,
                })
        }
        getFormFields={({ entity, isDisabled }) => [
          {
            fieldName: 'name',
            label: 'Name',
            isDisabled,
            isRequired: true,
            value: entity?.name || '',
            testId: 'Warehouse-name',
          },
          {
            fieldName: 'locationCode',
            label: 'Location Code',
            isDisabled,
            isRequired: true,
            value: entity?.locationCode || '',
            testId: 'Warehouse-locationCode',
          },
          {
            fieldName: 'allowParts',
            label: ALLOW_PARTS,
            isDisabled,
            value: entity?.allowParts || false,
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <Toggle
                  {...props}
                  {...errorProps}
                  testId={'Warehouse-allowParts'}
                  separatedLines
                  isDisabled={isDisabled}
                  value={'allowParts'}
                  isChecked={entity?.allowParts}
                  onChange={(event) => {
                    useAsForm.onFieldChange('allowParts', event.target.checked);
                  }}
                />
              );
            },
          },
        ]}
      />
    );
  };

  const getFacilityBtn = (item: iWarehouseUI) => (
    <FacilityBtn
      item={item}
      updateWarehouse={WarehouseService.updateWarehouse}
      reloadWarehouse={reloadWarehouse}
    />
  );

  const getAllowPartsBtn = (item: iWarehouseUI) => {
    return (
      <EntityEditPopupBtn<iWarehouseUI>
        entityName={'Allow Parts'}
        entity={item}
        onSaved={(saved) => updateLocalWarehouse(saved)}
        updateFn={(id, data) => WarehouseService.updateWarehouse(id, data)}
        btnTxt={EDIT_ALLOW_PART}
        getFormFields={({ entity, isDisabled }) => [
          {
            fieldName: 'allowParts',
            label: ALLOW_PARTS,
            isDisabled,
            value: entity?.allowParts || false,
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <Toggle
                  {...props}
                  {...errorProps}
                  testId={'Warehouse-allowParts'}
                  separatedLines
                  isDisabled={isDisabled}
                  value={'allowParts'}
                  isChecked={entity?.allowParts}
                  onChange={(event) => {
                    useAsForm.onFieldChange('allowParts', event.target.checked);
                  }}
                />
              );
            },
          },
        ]}
      />
    );
  };

  const afterMove = async (
    origin: iWarehouseLocation,
    moveReloaded: iWarehouseLocation,
  ) => {
    try {
      let newItems = [...state.items];
      //  scenario: from's parent does not have any child after move
      if (origin.parentId) {
        const reloadFromParent: iWarehouseLocation =
          await WarehouseService.getWarehouseDetail(origin.parentId);
        newItems = newItems.map((w: iWarehouseLocation) =>
          w.id === reloadFromParent.id ? reloadFromParent : w,
        );
      }
      setState({
        ...state,
        items: newItems.map((w: iWarehouseLocation) =>
          w.id === moveReloaded.id ? moveReloaded : w,
        ),
      });
    } catch (error) {
      Toaster.showApiError(error);
    }
  };

  const getMoveBtn = (item: iWarehouseUI) => {
    return (
      <EntityEditPopupBtn<iWarehouseUI>
        getPopupTitle={() => `Move Location ${item.name}`}
        entityName={`Move Location ${item.name}`}
        entity={item}
        onSaved={async (saved) => await afterMove(item, saved)}
        updateFn={async (id, data) =>
          await WarehouseService.moveWarehouse({
            ...data,
            fromLocationId: item.id,
            toLocationId: _.get(data, 'asRoot') ? null : _.get(data, 'moveTo'),
          })
        }
        btnTxt={MOVE_WAREHOUSE}
        getFormFields={({ entity, isDisabled }) => [
          {
            fieldName: 'warningText',
            label: 'Warning Text',
            isDisabled,
            value: '',
            renderComponent: () => {
              return (
                <SectionMessage allowClose={false} appearance="warning">
                  {MOVE_WARNING_MESSAGE}
                </SectionMessage>
              );
            },
          },
          {
            fieldName: 'asRoot',
            label: 'Move to the root',
            isDisabled,
            value: !!_.get(entity, 'asRoot'),
            testId: 'Warehouse-move-asRoot',
            renderComponent: (props, useAsForm, errorProps) => {
              return (
                <Toggle
                  {...props}
                  {...errorProps}
                  separatedLines
                  value={'asRoot'}
                  isChecked={_.get(entity, 'asRoot')}
                  onChange={(event) => {
                    useAsForm.onFieldChange('asRoot', event.target.checked);
                  }}
                />
              );
            },
          },
          {
            fieldName: 'moveTo',
            label: 'Move Under',
            isDisabled,
            value: _.get(entity, 'moveTo') || '',
            testId: 'Warehouse-moveTo',
            isWatching: true,
            renderComponent: (props, useAsForm, errorProps, entity) => {
              return (
                !_.get(entity, 'asRoot') && (
                  <WarehouseLocationSelector
                    {...props}
                    {...errorProps}
                    onChange={(
                      option: iOptionWithData<iWarehouseLocation> | null,
                    ) => {
                      useAsForm.onFieldChange('moveTo', option?.value || '');
                    }}
                  />
                )
              );
            },
          },
        ]}
      />
    );
  };

  if (state.isLoading) {
    return <SpinWhenLoading />;
  }
  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            title={'Warehouses'}
            createBtn={getCreateBtn()}
          />
        ),
      }}
    >
      <WarehouseListWrapper className={'warehouse-list-wrapper'}>
        <TableTree>
          <Headers>
            <Header width={'50%'}>Name</Header>
            <Header width={'10%'}>Code</Header>
            <Header width={'10%'}>{ALLOW_PARTS}</Header>
            <Header width={'10%'}>No. of Products</Header>
            <Header width={'10%'}>Facility?</Header>
            <Header width={'10%'} />
          </Headers>
          <Rows
            items={arrayToTree(
              state.items.sort((a: iWarehouseLocation, b: iWarehouseLocation) =>
                UtilsService.compareStr(a.name, b.name),
              ),
              { parentProperty: 'parentId' },
            )}
            render={(item: iWarehouseUI) => (
              <Row
                expandLabel="Expand"
                collapseLabel="Collapse"
                itemId={item.id}
                onExpand={expandItem}
                items={item.children}
                hasChildren={
                  item.hasChild || (item.children && item.children.length > 0)
                }
              >
                <Cell singleLine>
                  <ComposeSecondaryText secondaryText={item.description}>
                    <Link to={`/b/warehouse/${item.id}`}>{item.name}</Link>
                  </ComposeSecondaryText>
                </Cell>
                <Cell singleLine>{item.locationCode}</Cell>
                <Cell singleLine>
                  {item.allowParts && (
                    <Icons.HipchatMediaAttachmentCountIcon
                      testId={'colorful-dot-icon'}
                      primaryColor={'#006644'}
                      label={'dot'}
                      size={'medium'}
                    />
                  )}
                </Cell>
                <Cell singleLine>
                  <TextBadge
                    badgeStr={
                      item.productsCount ? item.productsCount.toString() : '0'
                    }
                    badgeType={
                      item.productsCount && item.productsCount > 0
                        ? 'primary'
                        : 'default'
                    }
                  />
                </Cell>
                <Cell singleLine>
                  {item.isFacility && (
                    <Icons.LocationIcon
                      size={'small'}
                      label={'location'}
                      testId={'facility-location-icon'}
                      primaryColor={'#006644'}
                    />
                  )}
                </Cell>
                <Cell singleLine className={'warehouse-list-actions'}>
                  <MoreIconPopup testId={`${item.id}-popup-trigger-btn`}>
                    <PopUpContentContainer>
                      {getCreateBtn(item)}
                      {getMoveBtn(item)}
                      {getFacilityBtn(item)}
                      {getAllowPartsBtn(item)}
                      {getDeleteBtn(item)}
                    </PopUpContentContainer>
                  </MoreIconPopup>
                </Cell>
              </Row>
            )}
          />
        </TableTree>
      </WarehouseListWrapper>
    </ComponentWithPageHeader>
  );
};
export default WarehouseLocationList;
