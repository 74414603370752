import BuildPackList from '../../components/buildPack/BuildPackList';
import ProtectedPage from '../../layouts/ProtectedPage';

const BuildPackPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `BuildPack`,
      }}
    >
      {(headerProps) => <BuildPackList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default BuildPackPage;
