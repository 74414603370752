import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import styled from 'styled-components';
import React, { ReactNode, useState } from 'react';
import Spinner from '../frameWork/Spinner';
import iAttribute, {
  AttributeForSections,
  AttributeTypes,
} from '../../types/attribute/iAttribute';
import Tokens from '../frameWork/Tokens';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import EntityNames from '../../helpers/EntityNames';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import AttributeInputHelper from './components/AttributeInputHelper';
import FormField from '../frameWork/FormField';
import { ViewWrapper } from '../frameWork/InlineEdit';
import AttributeItemEditPopupBtn from './components/AttributeItemEditPopupBtn';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import Heading from '../frameWork/Heading';
import Tooltip from '../frameWork/Tooltip';
import Flex from '../frameWork/Flex';
import iAttributeValue from '../../types/attribute/iAttributeValue';
import RefreshIconBtn from '../pagination/RefreshIconBtn';
import { iErrorMap } from '../form/FormError';

const Wrapper = styled.div`
  min-height: ${Tokens('space.800', '4rem')};
  .content-row {
    display: flex;
    flex-wrap: wrap;
    gap: ${Tokens('space.100', '0.5rem')};
    align-items: start;
    form:first-child {
      margin-block-start: ${Tokens('space.150', '0.75rem')};
    }
    .attr-editor {
      min-width: 10rem;
    }
    .form-field-wrapper {
      margin-right: ${Tokens('space.150', '0.75rem')};
    }
    .view-wrapper:hover {
      background-color: ${Tokens('color.background.accent.gray.subtlest')};
    }
  }

  .ellipsis {
    max-width: 220px;
  }
`;

type iAttributeValueEditSingleItem = {
  title?: ReactNode;
  attributeSetCode: AttributeSetCodes;
  className?: string;
  testId?: string;
  entityId: string;
  entityName: EntityNames;
  isDisabled?: boolean;
  attributeSection: AttributeForSections;
  providedAttrSet?: iAttributeSet;
  onRefreshBtnClicked?: () => void;
};
const AttributeValueEditSingleItem = ({
  title,
  attributeSetCode,
  className = '',
  testId = '',
  entityId,
  entityName,
  isDisabled = false,
  attributeSection,
  providedAttrSet,
  onRefreshBtnClicked,
}: iAttributeValueEditSingleItem) => {
  const testIdStr = `${testId}-attribute-value-edit-single-item`;
  const [attributes, setAttributes] = useState<iAttribute[]>([]);
  const [viewingAttrSet, setViewingAttrSet] = useState<iAttributeSet | null>(
    null,
  );
  const { state, onRefresh } = useListCrudHook<iAttributeItemWithValueMap>({
    getFn: async () => {
      const { attributes, itemsWithValueMap, attributeSet } =
        await AttributeInputHelper.getDataForInputPanel({
          entityId,
          entityName,
          attributeSetCode,
          providedAttrSet,
        });
      setAttributes(attributes);
      setViewingAttrSet(attributeSet);
      return itemsWithValueMap;
    },
  });

  const getTitleRow = () => {
    if (title !== undefined) {
      return <div>{title}</div>;
    }
    return (
      <Heading size={'small'}>
        <Flex className={'align-items-center gap-05'}>
          {AttributeSettingsHelper.getAttributeSetLink(viewingAttrSet)}
          <RefreshIconBtn
            onClick={() => {
              if (onRefreshBtnClicked) {
                onRefreshBtnClicked();
                return;
              }
              onRefresh();
            }}
          />
        </Flex>
      </Heading>
    );
  };
  const getReadViewClass = (
    attribute: iAttribute,
    isTextField: boolean,
    attValue?: iAttributeValue,
    errors: iErrorMap = {},
  ) => {
    const classNames = ['view-wrapper'];
    if (isTextField) {
      classNames.push('ellipsis');
    }

    if (isDisabled) {
      classNames.push('disabled-view');
    }

    if (
      [
        AttributeTypes.BUIlD_SIZE_CHANGER,
        AttributeTypes.ATTRIBUTE_ALIAS,
      ].indexOf(attribute.type as AttributeTypes) >= 0
    ) {
      classNames.push('no-padding');
      return classNames;
    }

    if (!attValue) {
      classNames.push('default-view');
    }
    if (attribute.id in errors) {
      classNames.push('is-invalid');
    }
    return classNames;
  };

  const displayReadView = (
    onClick: () => void,
    className: string,
    content: ReactNode,
  ) => {
    return (
      <ViewWrapper
        onClick={() => {
          if (isDisabled) {
            return;
          }
          onClick();
        }}
        className={className}
      >
        {content}
      </ViewWrapper>
    );
  };

  const getReadView = (
    onClick: () => void,
    isTextField: boolean,
    attribute: iAttribute,
    attValue?: iAttributeValue,
    isRequired?: boolean,
  ) => {
    const errors = AttributeInputHelper.getAttributeValueErrors({
      attribute,
      attributeSection,
      attributeValue: attValue,
    });
    return displayReadView(
      onClick,
      getReadViewClass(attribute, isTextField, attValue, errors)
        .join(' ')
        .trim(),
      AttributeInputHelper.displayValue(
        entityId,
        entityName,
        attribute,
        attValue,
        isRequired,
      ),
    );
  };

  const getContent = () => {
    // if there is an error of getting data, then don't display the input boxes.
    if (state.isLoading === true) {
      return <Spinner />;
    }

    const items = state.data.data || [];
    const item = items.length > 0 ? items[0] : null;
    return (
      <>
        {getTitleRow()}
        <div className={'content-row'}>
          {AttributeSettingsHelper.filterReadableAttrsForSection(
            attributes,
            attributeSection,
          ).map((attribute) => {
            const attrValueMap = item?.valuesMap || {};
            const attValue =
              attribute.id in attrValueMap
                ? attrValueMap[attribute.id]
                : undefined;
            const isTextField =
              [
                AttributeTypes.INPUT_TEXT,
                AttributeTypes.INPUT_TEXTAREA,
              ].indexOf(attribute.type as AttributeTypes) >= 0;
            const text = `${attValue?.value || ''}`.trim();
            const isRequired =
              AttributeSettingsHelper.isAttributeRequiredForSection(
                attribute,
                attributeSection,
              );
            return (
              <FormField
                key={attribute.id}
                label={attribute.name}
                isRequired={isRequired}
                render={() => {
                  return (
                    <AttributeItemEditPopupBtn
                      needQty={false}
                      needArea={false}
                      attributeItem={item || undefined}
                      attributeSetCode={attributeSetCode}
                      entityId={entityId}
                      entityName={entityName}
                      onSaved={() => onRefresh()}
                      onClose={() => onRefresh()}
                      attributeSection={attributeSection}
                      renderBtn={(onClick) => {
                        if (isTextField !== true || text === '') {
                          return getReadView(
                            onClick,
                            isTextField,
                            attribute,
                            attValue,
                            isRequired,
                          );
                        }
                        return (
                          <Tooltip
                            content={
                              <pre style={{ overflow: 'auto' }}>{text}</pre>
                            }
                          >
                            {getReadView(
                              onClick,
                              isTextField,
                              attribute,
                              attValue,
                              isRequired,
                            )}
                          </Tooltip>
                        );
                      }}
                    />
                  );
                }}
              />
            );
          })}
        </div>
      </>
    );
  };

  if (!viewingAttrSet) {
    return null;
  }

  return (
    <Wrapper
      className={`attribute-value-single-set ${className}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttributeValueEditSingleItem;
