import iContactCompanyProduct from '../../types/contact/iContactCompanyProduct';
import ServiceHelper from '../helper/ServiceHelper';

const endPoint = '/contactCompanyProduct';

const ContactCompanyProductService = {
  getAll: ServiceHelper.getPaginatedAll<iContactCompanyProduct>(endPoint),
  get: ServiceHelper.getById<iContactCompanyProduct>(endPoint),
  create: ServiceHelper.create<iContactCompanyProduct>(endPoint),
  update: ServiceHelper.updateById<iContactCompanyProduct>(endPoint),
  deactivate: ServiceHelper.deactivateById<iContactCompanyProduct>(endPoint),
};

export default ContactCompanyProductService;
