import iAttributeSet from '../../types/attribute/iAttributeSet';
import AttributeSetSettingsHelper, {
  iAttrSetDisplayGroup,
} from './AttributeSetSettingsHelper';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import md5 from 'md5';
import { iTableColumn } from '../../helpers/DynamicTableHelper';
import AttributeSelector from '../attribute/AttributeSelector';
import * as _ from 'lodash';
import { iOptionWithData } from '../frameWork/Select';
import iAttribute from '../../types/attribute/iAttribute';

type iAttributeSettingsDisplayGroupTable = iComponentWithPageHeader & {
  attributeSet?: iAttributeSet;
  onChanged: (groups: iAttrSetDisplayGroup[]) => void;
};

const AttributeSettingsDisplayGroupTbl = ({
  attributeSet,
  headerProps,
  onChanged,
  ...props
}: iAttributeSettingsDisplayGroupTable) => {
  const displayGroupSettings =
    AttributeSetSettingsHelper.getDisplayGroup(attributeSet) || {};
  const displayGroups = displayGroupSettings.groups || [];

  const { renderDataTable, renderEntityEditPopBtn, renderDeleteBtn } =
    useListCrudHook<iAttrSetDisplayGroup>({
      getFn: async () => {
        return {
          data: displayGroups,
          total: displayGroups.length,
          page: 1,
          currentPage: 1,
          perPage: displayGroups.length,
        };
      },
    });

  const getColumns = (): iTableColumn<iAttrSetDisplayGroup>[] => [
    {
      key: 'groupName',
      header: 'Group Name',
      cell: ({ data }) => data.groupName || '',
    },
    {
      key: 'attrIds',
      header: 'Attributes',
      cell: ({ data }) => {
        const attributeIds = data.attrIds || [];
        return (
          <AttributeSelector
            isMulti
            values={attributeIds}
            attributeSetCodes={attributeSet?.code ? [attributeSet?.code] : []}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(selected: any) => {
              const index = _.findIndex(displayGroups, { id: data.id });
              if (index >= 0) {
                displayGroups[index].attrIds =
                  selected === null
                    ? []
                    : selected.map(
                        (s: iOptionWithData<iAttribute>) => s.data.id,
                      );
                onChanged(displayGroups);
              }
            }}
          />
        );
      },
    },
    {
      key: 'btns',
      header: '',
      cell: ({ data }) => {
        return (
          <div className={'text-right'}>
            {renderDeleteBtn({
              deletingModel: data,
              getDisplayName: () => data.groupName || 'This group',
              deleteFnc: async () =>
                onChanged(displayGroups.filter((g) => g.id !== data.id)),
            })}
          </div>
        );
      },
    },
  ];

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    // Remove the item from the source position
    const [movedItem] = displayGroups.splice(sourceIndex, 1);
    // Insert it at the destination position
    displayGroups.splice(targetIndex, 0, movedItem);
    onChanged(displayGroups);
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            title={headerProps?.children}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            createBtn={renderEntityEditPopBtn<iAttrSetDisplayGroup>({
              entityName: 'New Display Group',
              createFn: async (data) => {
                onChanged([
                  ...displayGroups,
                  {
                    groupName: data.groupName,
                    attrIds: [],
                    id: md5(JSON.stringify(data)),
                  },
                ]);
              },
              updateFn: async () => null,
              getFormFields: ({ entity, isDisabled }) => [
                {
                  fieldName: 'groupName',
                  label: 'Name',
                  isDisabled,
                  isRequired: true,
                  value: entity?.groupName || '',
                  testId: 'groupName',
                },
              ],
            })}
          />
        ),
      }}
      {...props}
    >
      {renderDataTable({
        columns: getColumns(),
        showPageSizer: false,
        showPagination: false,
        tblProps: {
          isRankable: true,
          className: 'display-group-tbl',
          onRankEnd: (params) =>
            submitReorder(params.sourceIndex, params.destination?.index || 0),
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default AttributeSettingsDisplayGroupTbl;
