import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import iAddress from '../../types/system/iAddress';
import AddressService from '../../services/system/AddressService';

const AddressSelector = (props: iPreloadAsyncSelectProps) => {
  return (
    <PreloadedAsyncSelector<iAddress>
      placeholder={'Select an Addresss ...'}
      {...props}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return AddressService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { street: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        AddressService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
      renderOption={(data: iAddress) => ({
        label: `${data.street} ${data.suburb}`,
        value: `${data.id}`,
        data,
      })}
    />
  );
};

export default AddressSelector;
