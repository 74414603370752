import React from 'react';
import _ from 'lodash';

export const NUMBER_ROUND_DECIMAL = 3;

const ConversionText = ({
  value,
  contactCompanyUnit,
  conversion,
  productUnit,
  decimal = 2,
}: {
  value: number;
  contactCompanyUnit: string;
  conversion: number;
  productUnit: string;
  decimal?: number;
}) => {
  //  default round to integer
  const numberRound = (num: string | number | null, decimal?: number) => {
    if (num === null) return null;
    const convert = Number(num);
    //  eslint-disable-next-line no-nested-ternary
    const result = Number.isNaN(convert)
      ? num
      : decimal !== undefined
        ? _.round(convert, decimal)
        : _.round(convert, 0);
    return result.toLocaleString();
  };
  const prepareConversionEquation = React.useCallback(
    ({
      value,
      conversion,
      contactCompanyUnit,
      productUnit,
    }: {
      value: number;
      conversion: number;
      productUnit: string;
      contactCompanyUnit: string;
    }) => {
      return `${contactCompanyUnit} = ${numberRound(
        value * conversion,
        NUMBER_ROUND_DECIMAL,
      )} ${productUnit}`;
    },
    [],
  );
  return (
    <div data-testid={'conversion-text'}>
      {`${value.toLocaleString('en', { maximumFractionDigits: decimal })} ${prepareConversionEquation(
        {
          value,
          conversion,
          productUnit,
          contactCompanyUnit,
        },
      )}`}
    </div>
  );
};

export default ConversionText;
