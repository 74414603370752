import iAttribute from '../../types/attribute/iAttribute';
import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { getErrorProps, iErrorMap } from '../form/FormError';
import AttributeService from '../../services/attribute/AttributeService';
import Toaster from '../common/Toaster';
import { iOption } from '../frameWork/Select';
import AttributeSetSelector from '../attributeSet/AttributeSetSelector';

type iAttributeMovePopupBtn = Omit<iPopupBtn, 'titleId'> & {
  isDisabled?: boolean;
  attribute: iAttribute;
  onSaved?: (saved: iAttribute) => void;
  onClose?: (setModelShowing: iSetShowingModalFn) => void;
};
const AttributeMovePopupBtn = ({
  isDisabled,
  attribute,
  onClose,
  onSaved,
  ...props
}: iAttributeMovePopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
    onClose && onClose(setModelShowing);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const data = { ...(attribute || {}), ...editingData };
    if (`${data.attributeSetCode || ''}`.trim() === '') {
      errors.attributeSetCode = ['New Attribute Set is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    setIsSaving(true);
    AttributeService.update(attribute.id, {
      attributeSetCode: `${editingData.attributeSetCode || attribute.attributeSetCode}`,
    })
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: (
          <>
            Moving attribute <small>{attribute?.name || ''}</small> to another
            AttributeSet
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Move',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <>
            <div>
              You are about to move this attribute to the new Attribute Set
              below:
            </div>
            <AttributeSetSelector
              value={editingData.attributeSetCode || attribute.attributeSetCode}
              label={'New Attribute Set'}
              {...getErrorProps({
                error: errorMap,
                fieldName: 'attributeSetCode',
              })}
              onChange={(selected: iOption) => {
                setEditingData({
                  ...editingData,
                  attributeSetCode: selected.value,
                });
              }}
            />
          </>
        ),
      })}
    />
  );
};

export default AttributeMovePopupBtn;
