import iBaseType from '../iBaseType';
import iAttributeSet from './iAttributeSet';

export enum AttributeTypes {
  DROPDOWN = 'dropdown',
  INPUT_TEXT = 'input_text',
  INPUT_TEXTAREA = 'input_textarea',
  INPUT_NUMBER = 'input_number',
  INPUT_DATE_PICKER = 'input_datepicker',
  CHECKBOX = 'checkbox',

  // Advanced
  FILE_UPLOADER = 'file_uploader',
  PRODUCT_DROPDOWN = 'product_dropdown',
  SUPPLIER_DROPDOWN = 'supplier_dropdown',
  HOUSE_AREA_DROPDOWN = 'house_area_dropdown',
  BUIlD_SIZE_CHANGER = 'build_size_changer',
  ATTRIBUTE_ALIAS = 'attribute_alias',
}

export enum AttributeForSections {
  CONTRACT = 'Contract',
  PRELIM = 'Prelim',
  PLANS = 'Plans',
  PERMIT = 'Permit',
  ORDERS = 'Orders',
  TRACKING = 'Tracking',
}

type iAttribute = iBaseType & {
  name: string;
  type: string;
  attributeSetCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
  sort: number;
  isRequired: boolean;
  defaultValue: string | null;
  AttributeSet?: iAttributeSet;
};

export default iAttribute;
