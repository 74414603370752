import ComponentWrapper from '../frameWork/ComponentWrapper';
import iAttribute from '../../types/attribute/iAttribute';
import EntityNames from '../../helpers/EntityNames';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import AttributeInputHelper from './components/AttributeInputHelper';
import { useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import { AttributeSetCodes } from '../../types/attribute/iAttributeSet';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import Flex from '../frameWork/Flex';

export type iAttributeAliasValueDiv = {
  targetAttribute: iAttribute;
  entityName: EntityNames;
  entityId: string;
  testId?: string;
  className?: string;
};
const AttributeAliasValueDiv = ({
  testId,
  className,
  targetAttribute,
  entityId,
  entityName,
}: iAttributeAliasValueDiv) => {
  const [sourceAttribute, setSourceAttribute] = useState<iAttribute | null>(
    null,
  );
  const [sourceAttrItemsWithValueMap, setSourceAttrItemsWithValueMap] =
    useState<iAttributeItemWithValueMap[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { attributeId, attributeSetCode } =
    AttributeSettingsHelper.getAliasTargetAttrConf(targetAttribute);
  const sourceAttrId = `${attributeId || ''}`.trim();
  const sourceAttrSetCode = `${attributeSetCode || ''}`.trim();

  useEffect(() => {
    if (sourceAttrId === '') {
      setSourceAttribute(null);
      return;
    }

    let isCancelled = false;
    setIsLoading(true);
    AttributeInputHelper.getDataForInputPanel({
      entityId,
      entityName,
      attributeSetCode: sourceAttrSetCode as AttributeSetCodes,
      attributeId: sourceAttrId,
    })
      .then((resp) => {
        if (isCancelled) {
          return;
        }
        const { attributes, itemsWithValueMap } = resp;
        setSourceAttribute(attributes.length > 0 ? attributes[0] : null);
        setSourceAttrItemsWithValueMap(itemsWithValueMap.data || []);
      })
      .catch((err) => {
        if (isCancelled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCancelled) {
          return;
        }
        setIsLoading(false);
      });

    return () => {
      isCancelled = true;
    };
  }, [entityName, entityId, sourceAttrId, sourceAttrSetCode]);

  const getContent = () => {
    if (sourceAttrId === '' || sourceAttrSetCode === '' || !sourceAttribute) {
      return null;
    }
    return (
      <Flex>
        {sourceAttrItemsWithValueMap.map((sourceAttrItemWithValueMap) => {
          const valuesMap = sourceAttrItemWithValueMap.valuesMap || {};
          if (!(sourceAttrId in valuesMap)) {
            return null;
          }
          const attributeValue = valuesMap[sourceAttrId];
          return (
            <div key={sourceAttrItemWithValueMap.id}>
              {AttributeInputHelper.displayValue(
                entityId,
                entityName,
                sourceAttribute,
                attributeValue,
              )}
            </div>
          );
        })}
      </Flex>
    );
  };

  return (
    <ComponentWrapper
      componentName="AttributeAliasDiv"
      className={className}
      testId={testId}
      isLoading={isLoading}
    >
      {getContent()}
    </ComponentWrapper>
  );
};

export default AttributeAliasValueDiv;
